import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Content, UserContext } from '../../../core/models';
import { Slug } from '../../router/slug';
import { KeyedByString } from '../../interface/keyed-by-string';
import { HttpParameters } from '../../interface/http-parameters';
import { HttpParameterTransformer } from '../../di/provider/http-parameter-transformer';
import { Transformable } from '../../transformer/transformable';
import { AppGatewayUrlService } from '../app-gateway-url/service';

@Injectable()
export class ContentRepository {
    public constructor(
        private http: HttpClient,
        private transformer: Slug,
        private appGatewayUrlService: AppGatewayUrlService,
        @Inject(HttpParameterTransformer) private httpParameterTransformer: Transformable<KeyedByString, HttpParameters>
    ) {
    }

    public fetchSignedCookie(): Observable<void> {
        return this.http.get<void>(`${ this.appGatewayUrlService.url }/content-signature`, { withCredentials: true });
    }

    public fetchByCategoryId(categoryId: number, sort?: string, limit?: number, context?: UserContext): Observable<Content[]> {
        const params = this.httpParameterTransformer.transform({ sort, limit, ...context });

        return this.http.get<Content[]>(`${ this.appGatewayUrlService.url }/category/${ categoryId }/content`, { params })
            .pipe(map((content: Content[]) => this.mapSlugsToContent(content)));
    }

    public fetchByPlaylistId(playlistId: number, sort?: string, limit?: number, context?: UserContext): Observable<Content[]> {
        const params = this.httpParameterTransformer.transform({ sort, limit, ...context });

        return this.http.get<Content[]>(`${ this.appGatewayUrlService.url }/playlists/${ playlistId }/content`, { params })
            .pipe(map((content: Content[]) => this.mapSlugsToContent(content)));
    }

    public fetchById(contentId: number): Observable<Content> {
        return this.http.get<Content>(`${ this.appGatewayUrlService.url }/content/${ contentId }`)
            .pipe(map((content: Content) => this.mapSlugToContent(content)));
    }

    public search(query: string, matchOn: string, context?: UserContext, limit?: number): Observable<Content[]> {
        const params = this.httpParameterTransformer.transform({ query, matchOn, limit, ...context });

        return this.http.get<Content[]>(`${ this.appGatewayUrlService.url }/search`, { params })
            .pipe(map((content: Content[]) => this.mapSlugsToContent(content)));
    }

    private mapSlugsToContent(content: Content[]): Content[] {
        return content.map(content => this.mapSlugToContent(content));
    }

    private mapSlugToContent(content: Content): Content {
        const slug = this.transformer.create(content.name, content.id);

        return Object.assign(content, { slug });
    }
}
