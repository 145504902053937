import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UnauthenticatedHttpClient } from '../../providers/unauthenticated-http-client';
import { Environment } from '../../models/environment';

@Injectable()
export class EnvironmentRepository {
    public readonly appGateway: string = `https://${environment.appGatewayEndpoint}`;

    constructor(private http: UnauthenticatedHttpClient) {
    }

    public get(): Observable<Environment> {
        return this.http.get<Environment>(
            `${ this.appGateway }/environment`,
            {
                headers: {
                    'adopt-origin': window.location.origin,
                    token: 'none',
                }
            }
        );
    }
}
