import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class PageTitle {
    public constructor(private title: Title) {
    }

    public setTitle(newTitle: string) {
        this.title.setTitle(`${ newTitle } - Training Pages`);
    }
}
