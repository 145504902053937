import { Component, Input } from '@angular/core';
import { Content } from '../../../../core/models';
import { Type } from '../../../../core/enums/Type';

@Component({
    selector: 'app-content-list-item',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class ContentListItemComponent {
    @Input() public content: Content;
    public types = Type;
}
