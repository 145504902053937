import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, race, SchedulerLike, Subject } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap, mapTo, debounceTime, tap } from 'rxjs/operators';

import { Content } from '../../../../core/models';
import { MatchOnType } from '../../../../core/enums/MatchOnType';

import { ContentRepository } from '../../../../shared/services/content/content-repository';
import { Context } from '../../../../shared/user-context/context';
import { AsyncScheduler } from '../../../../shared/di/provider/async-provider';

import { TypeaheadResultSelected } from '../../event/search/typeaheadResultSelected';

@Component({
    selector: 'app-search-bar',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class SearchBarComponent implements OnInit {
    @ViewChild('typeahead') typeaheadDirectiveInstance;

    searchQuery: string;
    searchResults: Observable<any>;
    typeaheadResults: Content[] = [];
    public readonly SEARCH_QUERY_MIN_LENGTH = 3;
    public readonly SEARCH_QUERY_MAX_LENGTH = 84;
    public readonly MAX_NUMBER_OF_SEARCH_RESULTS = 5;
    public searchTerm: string;

    private cancelTypeAhead = new Subject<void>();

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        if ((event.code === 'Enter' || event.key === 'Enter') && this.typeaheadDirectiveInstance._typeahead.active === undefined) {
            this.navigateToSearchPage();
        }
    }

    public constructor(private router: Router,
                       private repository: ContentRepository,
                       private context: Context,
                       @Inject(AsyncScheduler) private scheduler: SchedulerLike,
                       private activatedRoute: ActivatedRoute,
                       private typeaheadResultSelectedEvent: TypeaheadResultSelected) {
    }

    public ngOnInit(): void {
        this.setSearchQueryFromRoute();

        this.searchResults = race(
            this.typeaheadSearch(),
            this.cancelTypeAhead.asObservable().pipe(mapTo([]))
        );
    }

    private typeaheadSearch(): Observable<any> {
        return new Observable((observer: any) => {
            observer.next(this.searchQuery);
        }).pipe(
            debounceTime(400, this.scheduler),
            tap((query: string) => this.searchTerm = query),
            mergeMap((query: string) => this.repository.search(
                query,
                MatchOnType.WORD,
                this.context.get(),
                this.MAX_NUMBER_OF_SEARCH_RESULTS
            )),
            tap(results => this.typeaheadResults = results)
        );
    }

    public navigateToSearchPage(): void {
        this.cancelTypeAhead.next();

        if (this.searchQuery === undefined || this.searchQuery.length === 0) {
            return;
        }

        this.router.navigate([ 'search' ], { queryParams: { query: this.searchQuery }});
    }

    public navigateToContentItem(event: TypeaheadMatch): void {
        const content: Content = event.item;
        this.clearSearchField();

        this.router.navigate([ 'content', content.slug ]);
    }

    private clearSearchField(): void {
        this.searchQuery = undefined;
    }

    private setSearchQueryFromRoute(): void {
        if (false === this.activatedRoute.snapshot.queryParams.hasOwnProperty('query')) {
            return;
        }

        this.searchQuery = this.activatedRoute.snapshot.queryParams.query;
    }

    public dispatchTypeaheadResultSelectedEvent(typeaheadResult: TypeaheadMatch): void {
        const searchResults = this.typeaheadResults.map(typeaheadResult => typeaheadResult.name);
        const searchSelected = typeaheadResult.item.name;

        this.typeaheadResultSelectedEvent.searchTerm = this.searchTerm;
        this.typeaheadResultSelectedEvent.searchResults = searchResults;
        this.typeaheadResultSelectedEvent.searchSelected = searchSelected;
        this.typeaheadResultSelectedEvent.searchSelectedPosition = searchResults.indexOf(searchSelected) + 1;

        const event = this.typeaheadResultSelectedEvent.buildEvent();

        this.typeaheadResultSelectedEvent.dispatch(event);
    }
}
