import { Inject, Injectable } from '@angular/core';

import { StoreProvider } from '../../../../redux-store/providers';

import { EventDispatcher } from '../../../../shared/event/dispatch/EventDispatcher';
import { SharedEventClient } from '../../../../shared/event/sharedEventClient';
import { WindowInjectionToken } from '../../../../shared/di/provider/window';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class TypeaheadResultSelected extends SharedEventClient {
    protected eventType = 'Search Typeahead';
    public searchTerm: string;
    public searchResults: string[];
    public searchSelected: string;
    public searchSelectedPosition: number;

    constructor(
        @Inject(StoreProvider) protected store: StoreProvider,
        @Inject(EventDispatcher) protected eventDispatcher: EventDispatcher,
        @Inject(WindowInjectionToken) protected window: Window,
        @Inject(Context) protected context: Context,
    ) {
        super(store, eventDispatcher, window, context);
    }

    public eventDetail(): any {
        return {
            search_term: this.searchTerm,
            search_return: this.searchResults,
            search_selected: this.searchSelected,
            search_selected_position: this.searchSelectedPosition
        };
    }
}
