import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StoreProvider } from '../../../../redux-store/providers';
import {
    createActionResetActiveContent,
    createActionSetActiveContent
} from '../../redux/actions';

import { Slug } from '../../../../shared/router/slug';
import { ContentRepository } from '../../../../shared/services/content/content-repository';

import { Content } from '../../../../core/models';

@Injectable()
export class ActiveContentItemResolver implements Resolve<Content> {
    constructor(private slug: Slug,
                private contentRepository: ContentRepository,
                private store: StoreProvider,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Content> {
        const errorCodes = [ 404 ];

        this.store.dispatch(createActionResetActiveContent());

        const contentId = this.slug.parseId(route.params.contentSlug);

        if (null === contentId) {
            this.displayPageNotFound();
        }

        return this.contentRepository.fetchById(contentId)
            .pipe(
                tap(
                    (content: Content) => this.store.dispatch(createActionSetActiveContent(content)),
                    error => {
                        if (error instanceof HttpErrorResponse && errorCodes.includes(error.status)) {
                            this.displayPageNotFound();
                        }
                    })
            );
    }

    private displayPageNotFound(): void {
        this.router.navigate(['/page-not-found'], { skipLocationChange: true });
    }
}
