import { Injectable } from '@angular/core';
import { ContentRepository } from './content-repository';
import { interval, Observable } from 'rxjs';
import { mergeMap, mapTo } from 'rxjs/operators';

@Injectable()
export class ContentSignature {
    public constructor(private repository: ContentRepository) {
    }

    public onInterval(period: number): Observable<number> {
        return interval(period)
            .pipe(mergeMap(result => this.fetch().pipe(mapTo(result))));
    }

    public fetch(): Observable<void> {
        return this.repository.fetchSignedCookie();
    }
}
