import { EventClient } from './eventClient';
import { StoreProvider } from '../../redux-store/providers';
import { EventDispatcher } from './dispatch/EventDispatcher';
import { Context } from '../redux/interfaces';
import { EventContext } from './types/EventContext';
import { PageContext } from './types/PageContext';
import { UserContext } from './types/UserContext';
import { Context as UserContextClass } from '../user-context/context';

export abstract class SharedEventClient extends EventClient {
    protected constructor(protected store: StoreProvider,
                          protected eventDispatcher: EventDispatcher,
                          protected window: Window,
                          protected context: UserContextClass
    ) {
        super(eventDispatcher);
    }

    browserContext() {
        const { browser, operatingSystem, language } = this.applicationState().browser;

        return {
            browser,
            operating_system: operatingSystem,
            language,
            screen_resolution: `${ this.window.screen.width }x${ this.window.screen.height }`,
            browser_resolution: `${ this.window.innerWidth }x${ this.window.innerHeight }`,
        };
    }

    eventContext(): EventContext {
        const date = new Date();

        return {
            type: this.eventType,
            event_time: (date.toUTCString()),
            event_time_offset: (date.getTimezoneOffset()),
            module: 'Training Pages'
        };
    }

    pageContext(): PageContext {
        return this.applicationState().page;
    }

    userContext(): UserContext {
        const { roleIds, languageIds, locationIds } = this.context.get();

        return {
            auth: this.applicationState().userContextProvidedBy,
            session_id: this.applicationState().sessionId,
            role_ids: roleIds,
            language_ids: languageIds,
            location_ids: locationIds
        };
    }

    private applicationState(): Context {
        return this.store.getState().shared.context;
    }
}
