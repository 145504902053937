import { Inject, Injectable } from '@angular/core';
import Amplify from '@aws-amplify/core';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { timer } from 'rxjs';
import { StoreProvider } from '../../../redux-store/providers';
import { AmplifyToken } from '../../di/provider/amplify-provider';

@Injectable()
export class AmplifyCognitoTokenRefresh {
    private readonly ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

    constructor(@Inject(AmplifyToken) private amplify: typeof Amplify,
                private store: StoreProvider,
    ) {
    }

    public setupRefreshTimer(user: CognitoUser): void {
        const timeToExpiration = this.timeToIdTokenExpiration(this.idTokenExpiration(user.getSignInUserSession()));

        timer(timeToExpiration - this.ONE_MINUTE_IN_MILLISECONDS)
            .subscribe(() => {
                user.refreshSession(user.getSignInUserSession().getRefreshToken(), (error) => {
                    if (error) {
                        if (this.refreshTokenExpiredError(error)) {
                            this.amplify.Auth.federatedSignIn({
                                customProvider: this.store.getState().shared.cognitoConfiguration.customProvider,
                            });
                        }

                        console.error(error);

                        return;
                    }

                    this.setupRefreshTimer(user);
                });
            });
    }

    private idTokenExpiration(userSession: CognitoUserSession): number {
        return userSession.getIdToken().getExpiration();
    }

    private timeToIdTokenExpiration(expiration: number): number {
        const timeToExpiration = expiration * 1000 - Date.now();

        return timeToExpiration > 0 ? timeToExpiration : 0;
    }

    private refreshTokenExpiredError(error): boolean {
        return 'NotAuthorizedException' === error.code && 'Refresh Token has expired' === error.message;
    }
}
