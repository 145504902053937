<ul class="footer-list d-flex flex-wrap justify-content-center p-0 py-5 m-0">
    <li class="footer-list__item d-flex mb-2"
        [class.hide-on-mobile]="!playlist.showOnMobile" [class.hide-on-desktop]="!playlist.showOnDesktop"
        *ngFor="let playlist of playlists">
        <a class="footer-list__link"
           [title]="'View Playlist: ' + playlist.name"
           [routerLink]="['/playlist/', playlist.slug]"
           (click)="dispatchNavigationSelectedEvent(playlist)">{{ playlist.name }}</a>
    </li>
</ul>
