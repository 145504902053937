import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-brightcove-video-preview',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class BrightcoveVideoPreviewComponent {
    @Input() public videoId: string;
    @Input() public accountId: string;
    @Input() public contentId: number;
}
