import { Inject, Injectable } from '@angular/core';

import { CompanyContext } from './company-context/company-context';
import { UserContext as UserContextInterface } from '../../core/models/index';
import { UnmappedContext } from './unmapped-context/unmapped-context';
import { UserContextInjectionToken, } from '../di/provider/user-context';
import { UserContextProvidable } from '../interface/user-context-providable';

@Injectable()
export class Context {
    constructor(@Inject(UserContextInjectionToken) private userContext: UserContextProvidable,
                private companyContext: CompanyContext,
                private unmappedContext: UnmappedContext) {
    }

    public get(): UserContextInterface {
        const unmappedContext = this.unmappedContext.all();
        const context: UserContextInterface = this.fillContextValueWhenEmpty(this.userContext.get(), this.companyContext.all());

        return {
            roleIds: [ ...context.roleIds, ...unmappedContext.roleIds],
            languageIds: [ ...context.languageIds, ...unmappedContext.languageIds],
            locationIds: [ ...context.locationIds, ...unmappedContext.locationIds]
        };
    }

    private fillContextValueWhenEmpty(context: UserContextInterface, fill: UserContextInterface): UserContextInterface {
        for (const key in context) {
            if (context.hasOwnProperty(key)) {
                if (context[key] === null) {
                    context[key] = fill[key];
                }

                context[ key ] = context[ key ].filter(val => val !== null);
            }
        }

        return context;
    }
}
