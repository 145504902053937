import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

import { PlaylistThunk, rootInitialState, trainingPagesClientReducer } from './redux';
import { ReduxStoreModule } from '../../redux-store/module';
import { ContentThunk } from './redux/thunks';

import { TrainingPagesClientRoutingModule } from './routing/router';
import { ActiveContentItemResolver } from './routing/resolvers/active-content-item.resolve';
import { SearchResolver } from './routing/resolvers/search.resolve';
import { SettingsResolver } from './routing/resolvers/settings.resolve';
import { UserSessionResolver } from './routing/resolvers/user-session.resolve';

import { EventDispatcher } from '../../shared/event/dispatch/EventDispatcher';
import { SlugCreator } from '../../shared/helper/slug-creator';
import { SharedModule } from '../../shared/module';
import { Slug } from '../../shared/router/slug';
import { Context } from '../../shared/user-context/context';

import { ContentClicked } from './event/content/ContentClicked';
import { NavigationSelected } from './event/navigation-selected/navigationSelected';
import { ContentViewed } from './event/content/contentViewed';
import { SearchExecuted } from './event/search/searchExecuted';
import { SearchResultSelected } from './event/search/searchResultSelected';
import { TypeaheadResultSelected } from './event/search/typeaheadResultSelected';
import { LinkClicked } from './event/link-clicked/linkClicked';

import {
    BrightcoveVideoPreviewComponent,
    BrowseMenuComponent,
    BrowseMenuListComponent,
    PlaylistViewComponent,
    ContainerComponent,
    ContentListComponent,
    ContentListItemComponent,
    ContentItemComponent,
    ContentItemViewComponent,
    FooterComponent,
    FooterListComponent,
    HomeComponent,
    PdfPreviewComponent,
    SearchBarComponent,
    SearchResultsViewComponent,
} from './components';
import { SuggestedPlaylistResolver } from './routing/resolvers/suggested-playlist.resolve';
import { PlaylistsResolver } from './routing/resolvers/playlists.resolve';
import { ActivePlaylistResolver } from './routing/resolvers/active-playlist.resolve';
import { TrainingPagesLoaded } from '../../shared/event/loaded/trainingPagesLoaded';
import { CognitoAuthorizeComponent } from './components/cognito-callback/component';
import { SelfSelectVisibilityResolver } from './routing/resolvers/self-select-visibility.resolver';
import { PlaylistTitleResolver } from './routing/resolvers/playlist-title.resolve';
import { ActiveContentItemTitleResolver } from './routing/resolvers/active-content-item-title.resolve';
import { SearchTitleResolver } from './routing/resolvers/search-title.resolve';
import { ErrorModule } from '../../error/module';
import { SignedCookieResolver } from './routing/resolvers/signed-cookie.resolve';
import { AdoptPlayerResolver } from './routing/resolvers/adopt-player.resolve';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true
};

@NgModule({
    declarations: [
        BrightcoveVideoPreviewComponent,
        BrowseMenuComponent,
        BrowseMenuListComponent,
        PlaylistViewComponent,
        ContainerComponent,
        ContentListComponent,
        ContentListItemComponent,
        ContentItemViewComponent,
        ContentItemComponent,
        FooterComponent,
        FooterListComponent,
        HomeComponent,
        PdfPreviewComponent,
        SearchBarComponent,
        SearchResultsViewComponent,
        CognitoAuthorizeComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReduxStoreModule.registerReducer('trainingPagesClient', trainingPagesClientReducer, {}),
        ReduxStoreModule.registerInitialState({ trainingPagesClient: rootInitialState }),
        TrainingPagesClientRoutingModule,
        PerfectScrollbarModule,
        TypeaheadModule.forRoot(),
        FormsModule,
        BrowserAnimationsModule,
        ErrorModule
    ],
    providers: [
        ContentThunk,
        PlaylistThunk,
        SlugCreator,
        Slug,
        ActiveContentItemResolver,
        ActivePlaylistResolver,
        SettingsResolver,
        SearchResolver,
        PlaylistsResolver,
        SignedCookieResolver,
        AdoptPlayerResolver,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        NavigationSelected,
        ContentViewed,
        ContentClicked,
        Context,
        EventDispatcher,
        SearchExecuted,
        TrainingPagesLoaded,
        SearchResultSelected,
        TypeaheadResultSelected,
        SuggestedPlaylistResolver,
        LinkClicked,
        UserSessionResolver,
        SelfSelectVisibilityResolver,
        AuthenticatorService,
        PlaylistTitleResolver,
        ActiveContentItemTitleResolver,
        SearchTitleResolver,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TrainingPagesClientModule {}
