import { IAction } from '../../../../../redux-store/interfaces';
import {
    CONTENT_LIST,
    CONTENT_LIST_ERROR,
    CONTENT_LIST_SUCCESS,
    SET_ACTIVE_CONTENT,
    RESET_ACTIVE_CONTENT
} from './types';
import { Content } from '../../../../../core/models';

export function createActionContentList(): IAction {
    return {
        type: CONTENT_LIST
    };
}

export function createActionContentListSuccess(content: Content[]): IAction {
    return {
        type: CONTENT_LIST_SUCCESS,
        payload: content
    };
}

export function createActionContentListError(error): IAction {
    return {
        type: CONTENT_LIST_ERROR,
        payload: error
    };
}

export function createActionSetActiveContent(content: Content): IAction {
    return {
        type: SET_ACTIVE_CONTENT,
        payload: content
    };
}

export function createActionResetActiveContent(): IAction {
    return {
        type: RESET_ACTIVE_CONTENT
    };
}
