import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
    createActionPlaylistList,
    createActionPlaylistListError,
    createActionPlaylistListSuccess
} from '../../redux/actions/playlist';
import { StoreProvider } from '../../../../redux-store/providers';

import { PlaylistRepository } from '../../../../shared/services/playlist/playlist-repository';
import { Playlist } from '../../../../core/models';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class PlaylistsResolver implements Resolve<Playlist> {
    constructor(private repository: PlaylistRepository,
                private store: StoreProvider,
                private userContext: Context) {
    }

    resolve(): Observable<any> {
        this.store.dispatch(createActionPlaylistList());

        return this.repository.fetchAll(this.userContext.get()).pipe(
            tap(playlists => this.store.dispatch(createActionPlaylistListSuccess(playlists))),
            catchError(error => {
                this.handleError(error);

                return EMPTY;
            })
        );
    }

    private handleError(error) {
        this.store.dispatch(createActionPlaylistListError(error));

        return error;
    }
}
