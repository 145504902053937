import { Inject, Injectable } from '@angular/core';
import { UserAgentParser } from '../di/provider/user-agent-parser';
import { WindowInjectionToken } from '../di/provider/window';
import { BrowserContext, PageContext } from '../redux/interfaces';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ContextCreator {
    constructor(@Inject(UserAgentParser) private uaParser,
                @Inject(WindowInjectionToken) private window: Window,
                @Inject(DOCUMENT) private document: Document) {
    }

    public pageContext(): PageContext {
        return <PageContext> {
            title: this.document.title,
            url: this.window.location.href,
            referer: '' === this.document.referrer ? 'Direct' : this.document.referrer,
        };
    }

    public browserContext(): BrowserContext {
        const browser = this.uaParser.getBrowser();
        const os = this.uaParser.getOS();

        return <BrowserContext> {
            operatingSystem: `${ os.name } ${ os.version }`,
            browser: `${ browser.name } ${ browser.version }`,
            language: this.window.navigator.language
        };
    }
}
