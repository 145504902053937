import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { StoreProvider } from '../../../../redux-store/providers';

import {
    createActionContentList,
    createActionContentListError,
    createActionContentListSuccess
} from '../../redux/actions/content';

import { Content } from '../../../../core/models';
import { MatchOnType } from '../../../../core/enums/MatchOnType';

import { ContentRepository } from '../../../../shared/services/content/content-repository';
import { Context } from '../../../../shared/user-context/context';

import { SearchExecuted } from '../../event/search/searchExecuted';

@Injectable()
export class SearchResolver implements Resolve<Content> {
    constructor(private repository: ContentRepository,
                private store: StoreProvider,
                private userContext: Context,
                private searchExecuted: SearchExecuted) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const query = route.queryParams.query;

        this.store.dispatch(createActionContentList());

        return this.repository.search(query, MatchOnType.WORD_AND_SUBSTRING, this.userContext.get()).pipe(
            tap(contentItems => this.store.dispatch(createActionContentListSuccess(contentItems))),
            tap(contentItems => this.dispatchSearchExecutedEvent(
                query,
                contentItems.map(contentItems => contentItems.name)
            )),
            catchError(error => this.handleError(error))
        );
    }

    private handleError(error) {
        this.store.dispatch(createActionContentListError(error));

        return error;
    }

    public dispatchSearchExecutedEvent(query, contentItems): void {
        this.searchExecuted.searchTerm = query;
        this.searchExecuted.searchResults = contentItems;

        const event = this.searchExecuted.buildEvent();

        this.searchExecuted.dispatch(event);
    }
}
