import { Component, Input, OnInit } from '@angular/core';
import { Content, Playlist } from '../../../../core/models';
import { ContentViewed } from '../../event/content/contentViewed';

@Component({
    selector: 'app-content-item',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class ContentItemComponent implements OnInit {
    @Input() public contentItem: Content;
    @Input() public playlist: Playlist;

    constructor(private contentViewed: ContentViewed) {
    }

    public ngOnInit(): void {
        this.dispatchContentViewedEvent();
    }

    public isPdf(): boolean {
        return this.contentItem.typeName === 'PDF';
    }

    public isVideo(): boolean {
        return this.contentItem.typeName === 'Video';
    }

    public dispatchContentViewedEvent() {
        this.contentViewed.contentItem = this.contentItem;
        this.contentViewed.playlist = this.playlist;

        const event = this.contentViewed.buildEvent();

        this.contentViewed.dispatch(event);
    }
}
