import { Injectable } from '@angular/core';
import { Transformable } from '../transformable';
import { KeyedByString } from '../../interface/keyed-by-string';

@Injectable()
export class UndefinedParameterTransformer implements Transformable<KeyedByString, KeyedByString> {
    transform(params: KeyedByString): KeyedByString {
        const transformedParams = {};

        Object.keys(params).forEach(key => {
            if (undefined !== params[key]) {
                transformedParams[key] = params[key];
            }
        });

        return transformedParams;
    }
}
