import { CanActivate } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AmplifySetup } from '../services/amplify/amplifySetup';
import { FakeAwsAuthClass } from '../services/auth/fake-aws-auth-class.service';
import { EnvironmentRepository } from '../services/authentication/environment-repository.service';
import { Injectable } from '@angular/core';
import {
    createCognitoConfiguration,
    createCognitoConfigurationError,
    createCognitoConfigurationSuccess,
    createCompanyRoutingIdentifier
} from '../redux/actions';
import { StoreProvider } from '../../redux-store/providers';
import { interfaceOfCognitoConfiguration } from '../models/cognito-configuration';

@Injectable()
export class AmplifyGuard implements CanActivate {
    constructor(private repository: EnvironmentRepository,
                private fakeAwsAuthClass: FakeAwsAuthClass,
                private amplify: AmplifySetup,
                private store: StoreProvider) {
    }

    public async canActivate(): Promise<boolean> {
        this.store.dispatch(createCognitoConfiguration());

        const { configuration, companyRoutingIdentifier } = await this.repository.get()
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.store.dispatch(createCognitoConfigurationError(error.message));

                    return throwError(error);
                })
            )
            .toPromise();

        if (undefined !== companyRoutingIdentifier && '' !== companyRoutingIdentifier) {
            this.store.dispatch(createCompanyRoutingIdentifier(companyRoutingIdentifier));
        }

        if (interfaceOfCognitoConfiguration(configuration)) {
            this.store.dispatch(createCognitoConfigurationSuccess(configuration));

            const { region, userPoolId, webClientId, domain, redirectSignIn, redirectSignOut, responseType } = configuration;

            const amplifyConfiguration = {
                Auth: {
                    region,
                    userPoolId,
                    userPoolWebClientId: webClientId,
                    oauth: {
                        domain,
                        redirectSignIn,
                        redirectSignOut,
                        responseType
                    }
                },
                Analytics: {
                    disabled: true
                }
            };

            this.amplify.configure(amplifyConfiguration);

            return true;
        }

        this.amplify.register(this.fakeAwsAuthClass);

        return true;
    }
}
