import { Component, Inject, OnInit } from '@angular/core';
import { HubClass } from '@aws-amplify/core/lib/Hub';
import { AmplifyHub } from '../../../../shared/di/provider/hub-provider';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cognito-callback',
    template: '',
})
export class CognitoAuthorizeComponent implements OnInit {
    public constructor(@Inject(AmplifyHub) private hub: HubClass,
                       private router: Router) {
    }

    public ngOnInit(): void {
        this.hub.listen('auth', ({ payload: { event } }) => {
            if ('signIn' === event) {
                this.router.navigate(['/']);
            }
        });
    }
}
