export const DefaultSettings = {
    applicationTitle: 'Training Pages',
    roleIds: [],
    languageIds: [],
    locationIds: [],
    suggestedPlaylistId: 0,
    logoUrl: 'https://cdn.applearn.tv/white-applearn-logo.png',
    primaryColor: '#11A3D5',
    backgroundColor: '#242424',
    backgroundUrl: null,
    contentSignatureRefreshInterval: 60 * 30 * 1000,
    adoptSnippet: { enabled: false },
};
