import { IAction } from '../../../redux-store/interfaces';
import {
    COGNITO_CONFIGURATION,
    COGNITO_CONFIGURATION_SUCCESS,
    COMPANY_ROUTING_IDENTIFIER,
    SELF_SELECT_CONTEXT,
    SELF_SELECT_VISIBILITY,
    SETTINGS_LIST,
    SETTINGS_LIST_SUCCESS
} from '../actions';
import { sharedInitialState } from './initial-state';
import { SharedInitialState } from '../interfaces';

export function sharedReducer({ contextReducer }) {
    const injectedContextReducer = contextReducer();

    return (currentState: SharedInitialState = sharedInitialState, action: IAction): SharedInitialState => {
        const { type, payload } = action;

        let newState;

        switch (type) {
            case SETTINGS_LIST:
                newState = { ...currentState, settings: null };
                break;
            case SETTINGS_LIST_SUCCESS:
                newState = { ...currentState, settings: { ...sharedInitialState.settings, ...payload }, settingsLoaded: true };
                break;
            case SELF_SELECT_CONTEXT:
                newState = { ...currentState, selfSelectContext: payload };
                break;
            case COGNITO_CONFIGURATION:
                newState = { ...currentState, cognitoConfiguration: null };
                break;
            case COGNITO_CONFIGURATION_SUCCESS:
                newState = { ...currentState, cognitoConfiguration: { ...sharedInitialState.cognitoConfiguration, ...payload } };
                break;
            case SELF_SELECT_VISIBILITY:
                newState = { ...currentState, selfSelectVisibility: payload };
                break;
            case COMPANY_ROUTING_IDENTIFIER:
                newState = { ...currentState, companyRoutingIdentifier: payload };
                break;
            default:
                newState = currentState;
                break;
        }

        const context = injectedContextReducer(newState.context, action);

        return { ...newState, context };
    };
}
