<nav class="navbar navbar-dark" [style.backgroundColor]="settings?.primaryColor">
    <a routerLink='/' class="navbar-brand d-inline-flex"
       [title]="'Home' + (settings?.applicationTitle ? ' - ' + settings?.applicationTitle : '')">
        <img class="navbar__logo img-fluid" *ngIf="settings?.logoUrl; else noCompanyLogo"
             [src]="settings?.logoUrl" [alt]="settings?.applicationTitle">

        <ng-template #noCompanyLogo>
            <i class="navbar__home-icon material-icons">home</i>
        </ng-template>
    </a>

    <i class="navbar__user-icon material-icons" *ngIf="selfSelectVisibility" (click)="openContextSelfSelect()">person</i>

    <div class="navbar__menu-button"
         (click)="toggleBrowseMenu()"
         [attr.title]="browseMenuIsOpen ? 'Close browse content menu' : 'Open browse content menu'">
        <div class="navbar__menu-icon" [class.navbar__menu-icon--close-icon]="browseMenuIsOpen"></div>
    </div>
</nav>
