import { FactoryProvider, InjectionToken } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';

export const Fingerprint = new InjectionToken('Fingerprint');

export function FingerprintFactory() {
    return Fingerprint2;
}

export const FingerprintProvider: FactoryProvider = {
    provide: Fingerprint,
    useFactory: FingerprintFactory
};
