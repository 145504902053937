import { Resolve } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import Amplify from '@aws-amplify/core';
import { AmplifyToken } from '../../../../shared/di/provider/amplify-provider';

import { ContextSelfSelectInjectionToken } from '../../../../shared/di/provider/context-self-select';
import { StoreProvider } from '../../../../redux-store/providers';
import { createActionSetSelfSelectVisibility } from '../../../../shared/redux';

@Injectable()
export class SelfSelectVisibilityResolver implements Resolve<boolean> {
    constructor(@Inject(AmplifyToken) private amplify: typeof Amplify,
                @Inject(ContextSelfSelectInjectionToken) protected contextSelfSelect,
                private store: StoreProvider
    ) {
    }

    async resolve(): Promise<boolean> {
        const user = await this.amplify.Auth.currentAuthenticatedUser();

        this.store.dispatch(createActionSetSelfSelectVisibility(
            null === user &&
            true === await this.contextSelfSelect.canDisplay())
        );

        return true;
    }
}
