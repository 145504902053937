import Amplify from '@aws-amplify/core';
import { from, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';
import { AmplifyToken } from '../di/provider/amplify-provider';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public static readonly UnauthenticatedAuthorizationHeaderValue = 'none';

    constructor(@Inject(AmplifyToken) private amplify: typeof Amplify) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.amplify.Auth.currentSession())
            .pipe(
                switchMap((cognitoUserSession: CognitoUserSession) => {
                    const idToken: CognitoIdToken = undefined === cognitoUserSession
                        ? undefined
                        : cognitoUserSession.getIdToken();

                    const httpParams = undefined === idToken
                        ? req.params
                        : req.params
                            .delete('roleIds')
                            .delete('languageIds')
                            .delete('locationIds');

                    const Authorization = undefined === idToken
                        ? AuthInterceptor.UnauthenticatedAuthorizationHeaderValue
                        : idToken.getJwtToken();

                    return next.handle(req.clone({
                        params: httpParams,
                        setHeaders: {
                            Authorization,
                            'token': 'none',
                        }
                    }));
                }),
                catchError(() => next.handle(req.clone())),
            );
    }
}
