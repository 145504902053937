import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { StoreProvider } from '../../../redux-store/providers';

@Injectable()
export class AppGatewayUrlService {
    public url: string;

    private apiUrl: string = environment.appGatewayEndpoint;
    private routingIdentifierStatePath = 'shared/companyRoutingIdentifier';

    constructor(private store: StoreProvider) {
        this.store.bind(this, this.routingIdentifierStatePath);
    }

    public onStateChanged(state: string, value: any): void {
        if (this.routingIdentifierStatePath === state) {
            this.url = this.setUrl(value);
        }
    }

    private setUrl(routingIdentifier: string): string {
        const subdomain = routingIdentifier ? `${ routingIdentifier }.` : '';

        return `https://${ subdomain + this.apiUrl }`;
    }
}
