import { IAction } from '../../../redux-store/interfaces';
import {
    BROWSER_CONTEXT,
    PAGE_CONTEXT,
    SELF_SELECT_CONTEXT,
    SETTINGS_LIST,
    SETTINGS_LIST_SUCCESS,
    SETTINGS_LIST_ERROR,
    USER_SESSION,
    COGNITO_CONFIGURATION,
    COGNITO_CONFIGURATION_SUCCESS,
    COGNITO_CONFIGURATION_ERROR,
    SELF_SELECT_VISIBILITY,
    USER_CONTEXT_PROVIDED_BY,
    COMPANY_ROUTING_IDENTIFIER
} from './types';

import { BrowserContext, PageContext, PartialSettings } from '../interfaces';
import { SelfSelectContext } from '../interfaces/selfSelectContext';

export function createActionSetBrowserContext(browserContext: BrowserContext = null): IAction {
    return {
        type: BROWSER_CONTEXT,
        payload: browserContext,
    };
}

export function createActionSetPageContext(pageContext: PageContext = null): IAction {
    return {
        type: PAGE_CONTEXT,
        payload: pageContext,
    };
}

export function createActionSetSelfSelectContext(selfSelectContext: SelfSelectContext = null): IAction {
    return {
        type: SELF_SELECT_CONTEXT,
        payload: selfSelectContext,
    };
}

export function createActionSettingsList(): IAction {
    return {
        type: SETTINGS_LIST
    };
}

export function createActionSettingsListSuccess(settings: PartialSettings): IAction {
    return {
        type: SETTINGS_LIST_SUCCESS,
        payload: settings
    };
}

export function createActionSettingsListError(error): IAction {
    return {
        type: SETTINGS_LIST_ERROR,
        payload: error
    };
}

export function createActionSetUserSession(hash: string): IAction {
    return {
        type: USER_SESSION,
        payload: hash
    };
}

export function createCognitoConfiguration(): IAction {
    return {
        type: COGNITO_CONFIGURATION
    };
}

export function createCognitoConfigurationSuccess(cognito_configuration): IAction {
    return {
        type: COGNITO_CONFIGURATION_SUCCESS,
        payload: cognito_configuration
    };
}

export function createCompanyRoutingIdentifier(companyRoutingIdentifier: string): IAction {
    return {
        type: COMPANY_ROUTING_IDENTIFIER,
        payload: companyRoutingIdentifier
    };
}

export function createCognitoConfigurationError(error): IAction {
    return {
        type: COGNITO_CONFIGURATION_ERROR,
        payload: error
    };
}

export function createActionSetSelfSelectVisibility(selfSelectVisibility): IAction {
    return {
        type: SELF_SELECT_VISIBILITY,
        payload: selfSelectVisibility
    };
}

export function createActionSetUserContextProvidedBy(providerValue: string): IAction {
    return {
        type: USER_CONTEXT_PROVIDED_BY,
        payload: providerValue
    };
}
