import { Injectable } from '@angular/core';
@Injectable()
export class SlugCreator {
    public create(name: string): string | null {
        const slug = name.replace(/[.]/g, ' ')
            .replace(/[']/g, '')
            .replace(/[\W]/g, '-').toLowerCase()
            .replace(/-{2,}/g, '-')
            .replace(/\-$/gi, '');

        return slug.length > 0 ? slug : null;
    }
}
