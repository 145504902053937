import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable, of } from 'rxjs';

@Injectable()
export class SearchTitleResolver implements Resolve<string> {
    resolve(route: ActivatedRouteSnapshot): Observable<string> {
        return of(route.queryParams.query);
    }
}
