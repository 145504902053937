<form role="search" (ngSubmit)="navigateToSearchPage()">
    <div class="search-bar py-3">
        <div class="container">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="material-icons">
                          search
                        </i>
                    </span>
                </div>

                <label for="search" class="sr-only">Search and browse for Training & Support Material</label>

                <input class="form-control ng-pristine"
                       type="search" name="search" id="search" title="Search for content"
                       autocomplete="off" placeholder="Search"
                       [(ngModel)]="searchQuery"
                       #typeahead=bs-typeahead
                       [typeahead]="searchResults"
                       [typeaheadMinLength]="SEARCH_QUERY_MIN_LENGTH"
                       [typeaheadOptionsLimit]="MAX_NUMBER_OF_SEARCH_RESULTS"
                       [typeaheadIsFirstItemActive]="false"
                       [typeaheadSelectFirstItem]="false"
                       [maxlength]="SEARCH_QUERY_MAX_LENGTH"
                       (typeaheadOnSelect)="dispatchTypeaheadResultSelectedEvent($event); navigateToContentItem($event)"
                       typeaheadOptionField="name">
            </div>
        </div>
    </div>
</form>
