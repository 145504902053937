<div class="content-card card h-100">
    <div class="content-card__body card-body p-0">
        <div class="content-card__image-container card-img-container mb-3">
            <img src="{{ content.holdingImage }}" class="content-card__image card-img-top" alt="{{ content.name }}">
        </div>

        <span class="card-name d-block px-3">{{ content.name }}</span>
    </div>

    <div class="content-card__footer card-footer px-3">
        <span *ngIf="types[ content.typeName ] === types.PDF" class="content-card__footer__text card-text content__type">
            {{ content.typeName }}
        </span>

        <span *ngIf="types[ content.typeName ] === types.Video" class="content-card__footer__text card-text content__duration">
            {{ content.contentFileDuration | videoDuration }}
        </span>
    </div>
</div>
