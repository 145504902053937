import { Inject, Injectable } from '@angular/core';

import { StoreProvider } from '../../../redux-store/providers';
import { EventDispatcher } from '../dispatch/EventDispatcher';

import { SharedEventClient } from '../sharedEventClient';
import { WindowInjectionToken } from '../../di/provider/window';
import { Context } from '../../user-context/context';

@Injectable()
export class TrainingPagesLoaded extends SharedEventClient {
    protected eventType = 'Training Pages Loaded';

    constructor(
        @Inject(StoreProvider) protected store: StoreProvider,
        @Inject(EventDispatcher) protected eventDispatcher: EventDispatcher,
        @Inject(WindowInjectionToken) protected window: Window,
        @Inject(Context) protected context: Context,
    ) {
        super(store, eventDispatcher, window, context);
    }

    public eventDetail(): any {
        return {};
    }
}
