import { IAction } from '../../../../redux-store/interfaces';

import { BROWSER_CONTEXT, PAGE_CONTEXT, USER_CONTEXT_PROVIDED_BY, USER_SESSION } from '../../actions';
import { Context } from '../../interfaces/context';

export function contextReducer() {
    return (currentState: Context, action: IAction): Context => {
        const { type, payload } = action;

        let newState;

        switch (type) {
            case BROWSER_CONTEXT:
                newState = { ...currentState, browser: payload };
                break;
            case PAGE_CONTEXT:
                newState = { ...currentState, page: payload };
                break;
            case USER_CONTEXT_PROVIDED_BY:
                newState = { ...currentState, userContextProvidedBy: payload };
                break;
            case USER_SESSION:
                newState = { ...currentState, sessionId: payload };
                break;
            default:
                newState = currentState;
                break;
        }

        return newState;
    };
}
