import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    HomeComponent,
    ContainerComponent,
    ContentItemViewComponent,
    SearchResultsViewComponent,
    PlaylistViewComponent,
} from '../components';

import { ActiveContentItemResolver } from './resolvers/active-content-item.resolve';
import { ActivePlaylistResolver } from './resolvers/active-playlist.resolve';
import { SelfSelectVisibilityResolver } from './resolvers/self-select-visibility.resolver';
import { SettingsResolver } from './resolvers/settings.resolve';
import { SearchResolver } from './resolvers/search.resolve';
import { SuggestedPlaylistResolver } from './resolvers/suggested-playlist.resolve';
import { PlaylistsResolver } from './resolvers/playlists.resolve';
import { UserSessionResolver } from './resolvers/user-session.resolve';

import { CognitoAuthorizeComponent } from '../components/cognito-callback/component';
import { AmplifyGuard } from '../../../shared/guards/amplify-guard';
import { AuthGuard } from '../../../shared/guards/auth-guard';
import { PlaylistTitleResolver } from './resolvers/playlist-title.resolve';
import { ActiveContentItemTitleResolver } from './resolvers/active-content-item-title.resolve';
import { SearchTitleResolver } from './resolvers/search-title.resolve';
import { SignedCookieResolver } from './resolvers/signed-cookie.resolve';
import { AdoptPlayerResolver } from './resolvers/adopt-player.resolve';

const routes: Routes = [
    {
        path: '',
        canActivate: [
            AmplifyGuard
        ],
        children: [
            {
                path: '',
                component: ContainerComponent,
                resolve: {
                    adoptPlayer: AdoptPlayerResolver,
                    playlists: PlaylistsResolver,
                    settings: SettingsResolver,
                    userSession: UserSessionResolver,
                    selfSelectVisibility: SelfSelectVisibilityResolver,
                    signedCookie: SignedCookieResolver,
                },
                children: [
                    {
                        path: '',
                        canActivate: [
                            AuthGuard
                        ],
                        children: [
                            {
                                path: '',
                                component: HomeComponent,
                                resolve: {
                                    activePlaylist: SuggestedPlaylistResolver,
                                    playlists: PlaylistsResolver,
                                },
                                pathMatch: 'full',
                                runGuardsAndResolvers: 'always',
                                data: { pageTitle: 'Home' }
                            },
                            {
                                path: 'playlist',
                                children: [
                                    {
                                        component: PlaylistViewComponent,
                                        resolve: {
                                            activePlaylist: ActivePlaylistResolver,
                                            pageTitle: PlaylistTitleResolver
                                        },
                                        path: ':playlistSlug'
                                    }
                                ]
                            },
                            {
                                path: 'content',
                                children: [
                                    {
                                        path: ':contentSlug',
                                        component: ContentItemViewComponent,
                                        resolve: {
                                            activeContent: ActiveContentItemResolver,
                                            pageTitle: ActiveContentItemTitleResolver
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'search',
                                component: SearchResultsViewComponent,
                                resolve: {
                                    searchResults: SearchResolver,
                                    pageTitle: SearchTitleResolver
                                },
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                            },
                        ]
                    },
                ]
            },
            {
                path: 'authorize',
                component: CognitoAuthorizeComponent,
                data: { pageTitle: 'Authenticating' }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TrainingPagesClientRoutingModule {}
