<div class="app-container d-flex flex-column">
    <div class="app-content flex-grow-1">
        <app-adopt-navbar></app-adopt-navbar>

        <app-search-bar></app-search-bar>

        <div class="container mb-5" role="main">
            <router-outlet></router-outlet>
        </div>
    </div>

    <app-footer></app-footer>

    <app-browse-menu [class.active]="browseMenuIsOpen"></app-browse-menu>
</div>
