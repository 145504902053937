import { Injectable } from '@angular/core';

import { UserContext as UserContextInterface } from '../../../core/models';
import { StoreProvider } from '../../../redux-store/providers';

@Injectable()
export class CompanyContext {
    constructor(private store: StoreProvider) {}

    public all(): UserContextInterface {
        const { roleIds, languageIds, locationIds } = this.store.getState().shared.settings;

        return {
            roleIds:  [...roleIds ],
            languageIds: [ ...languageIds ],
            locationIds: [ ...locationIds ]
        };
    }
}
