import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { StoreProvider } from '../../../redux-store/providers';
import { IOnStateChanged } from '../../../redux-store/interfaces';

import { Settings } from '../../redux/';

import { createActionBrowseMenuToggle } from '../../../modules/training-pages-client/redux/actions/browse-menu';
import { SelfSelectVisibility } from '../../redux/interfaces/selfSelectVisibility';
import { SelfSelectDecorator } from '../../services/self-select/SelfSelectDecorator';

@Component({
    selector: 'app-adopt-navbar',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class NavbarComponent implements OnInit, OnDestroy, IOnStateChanged {
    public browseMenuIsOpen = false;
    public settings: Settings;
    public selfSelectVisibility: SelfSelectVisibility;

    private boundStates = {
        settings: 'shared/settings',
        browseMenuState: 'trainingPagesClient/browseMenu',
        selfSelectVisibility: 'shared/selfSelectVisibility'
    };

    public constructor(
        private store: StoreProvider,
        private changeDetector: ChangeDetectorRef,
        private selfSelect: SelfSelectDecorator) {
    }

    public ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));
    }

    public onStateChanged(state, value): void {
        switch (state) {
            case this.boundStates.settings:
                this.updateProperty('settings', value);
                break;
            case this.boundStates.browseMenuState:
                this.browseMenuIsOpen = value.active;
                break;
            case this.boundStates.selfSelectVisibility:
                this.updateProperty('selfSelectVisibility', value);
                break;
        }
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    public toggleBrowseMenu(): void {
        this.store.dispatch(createActionBrowseMenuToggle());
    }

    public openContextSelfSelect(): void {
        this.selfSelect.display();
    }

    private updateProperty(property, v): void {
        this[ property ] = v;

        this.changeDetector.detectChanges();
    }
}
