import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import Amplify from '@aws-amplify/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserContextProvidedBy } from '../../core/enums/UserContextProvidedBy';
import { StoreProvider } from '../../redux-store/providers';
import { AmplifyToken } from '../di/provider/amplify-provider';
import { createActionSetUserContextProvidedBy } from '../redux/actions';
import { AmplifyCognitoTokenRefresh } from '../services/auth/amplify-cognito-token-refresh';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(@Inject(AmplifyToken) private amplify: typeof Amplify,
                private store: StoreProvider,
                private amplifyCognitoTokenRefresh: AmplifyCognitoTokenRefresh,
    ) {
    }

    private static userSessionExists(userSession: CognitoUser): boolean {
        return typeof userSession === 'object'
            && null !== userSession
            && userSession.hasOwnProperty('signInUserSession');
    }

    public async canActivate(): Promise<boolean> {
        try {
            const user: CognitoUser | null = await this.amplify.Auth.currentAuthenticatedUser();

            if (AuthGuard.userSessionExists(user)) {
                this.amplifyCognitoTokenRefresh.setupRefreshTimer(user);

                this.store.dispatch(createActionSetUserContextProvidedBy(UserContextProvidedBy.SSO));
            }

            return Promise.resolve(true);
        } catch (err) {
            await this.amplify.Auth.federatedSignIn(
                {
                    customProvider: this.store.getState().shared.cognitoConfiguration.customProvider,
                },
            );

            return Promise.resolve(false);
        }
    }
}
