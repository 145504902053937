import { InjectionToken } from '@angular/core';
import { ContextSelfSelect } from '@applearn/context-self-select/dist/factory/ContextSelfSelect';
import { DefaultSettings } from '../../../settings/defaultSettings';
import { StoreProvider } from '../../../redux-store/providers';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

export const ContextSelfSelectInjectionToken = new InjectionToken('ContextSelfSelectInjectionToken');

export function ContextSelfSelectFactory (store: StoreProvider) {
    const contextSelfSelect = ContextSelfSelect({
        dataSource: `https://${environment.appGatewayEndpoint}/context`,
        primaryColor: DefaultSettings.primaryColor,
        requestHeaders: [
            { name: 'Authorization', value: 'none' },
            { name: 'token', value: 'none' },
            { name: 'adopt-origin', value: window.location.origin }
        ]
    });

    store.select('shared/companyRoutingIdentifier')
        .pipe(filter(routingIdentifier => routingIdentifier !== null))
        .subscribe(routingIdentifier => {
            const subdomain = routingIdentifier ? `${ routingIdentifier }.` : '';
            contextSelfSelect.setConfigValue('dataSource', `https://${ subdomain + environment.appGatewayEndpoint }/context`);
        });

    return contextSelfSelect;
}

export const ContextSelfSelectProvider = {
    provide: ContextSelfSelectInjectionToken,
    useFactory: ContextSelfSelectFactory,
    deps: [ StoreProvider ]
};
