import { InjectionToken } from '@angular/core';
import { SelfSelectContextHandler } from '../../handlers/self-select-context-handler';

export const UserContextInjectionToken = new InjectionToken('UserContextInjectionToken');

export function UserContextFactory (selfSelectContext: SelfSelectContextHandler) {
    return selfSelectContext;
}

export const UserContextProvider = {
    provide: UserContextInjectionToken,
    useFactory: UserContextFactory,
    deps: [ SelfSelectContextHandler ]
};
