import { Transformable } from './transformable';

export class Transformer<I, O> implements Transformable<I, O> {
    private transformers: Transformable<any, any>[] = [];

    public attach(transformer: Transformable<any, any>): void {
        this.transformers.push(transformer);
    }

    public transform(toTransform: I): O {
        this.transformers.forEach(transformer => {
            toTransform = transformer.transform(toTransform);
        });

        return toTransform as any as O;
    }
}
