import { FactoryProvider, InjectionToken } from '@angular/core';
import { Hub } from '@aws-amplify/core';
import { HubClass } from '@aws-amplify/core/lib-esm/Hub';

export const AmplifyHub = new InjectionToken('Hub');

export function amplifyHubFactory(): HubClass {
    return Hub;
}

export const AmplifyHubProvider: FactoryProvider = {
    provide: AmplifyHub,
    useFactory: amplifyHubFactory
};
