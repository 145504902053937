import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Static } from 'fingerprintjs2';

import { Fingerprint } from '../../../../shared/di/provider/fingerprint-provider';
import { StoreProvider } from '../../../../redux-store/providers';
import { createActionSetUserSession } from '../../../../shared/redux/actions';

@Injectable()
export class UserSessionResolver implements Resolve<string> {
    constructor(private store: StoreProvider, @Inject(Fingerprint) private fingerprint: Static) {
    }

    resolve(): Promise<string> {
        return this.fingerprint.getPromise().then(components => {
            const values = components.map(component => component.value);
            const hash = this.fingerprint.x64hash128(values.join(''), 31);

            this.store.dispatch(createActionSetUserSession(hash));

            return hash;
        });
    }
}
