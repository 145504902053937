import { Inject, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import thunk from 'redux-thunk';

import { INITIAL_STATES, MIDDLEWARES, REDUCERS } from './tokens';
import { NgReduxModule } from '@angular-redux/store';
import { ReduxUtils } from './utils/redux-utils';
import { StoreEnhancer } from 'redux';
import { StoreProvider } from './providers';

export function initializedReducer() { return true; }

const providers = [
    { provide: INITIAL_STATES, multi: true, useValue: {} },
    { provide: MIDDLEWARES, multi: true, useValue: null },
    { provide: REDUCERS, multi: true, useValue: {} },
    ReduxUtils,
    StoreProvider
];

const imports = [
    NgReduxModule
];

@NgModule({ providers, imports })
export class ReduxStoreModule {
    static registerInitialState(initialState: Object): ModuleWithProviders<ReduxStoreModule> {
        return {
            ngModule: ReduxStoreModule,
            providers: [{
                provide: INITIAL_STATES, multi: true, useValue: initialState
            }]
        };
    }

    static registerMiddleware(middleware: Function): ModuleWithProviders<ReduxStoreModule> {
        return {
            ngModule: ReduxStoreModule,
            providers: [{
                provide: MIDDLEWARES, multi: true, useValue: middleware
            }]
        };
    }

    static registerReducer(state, injector, dependencies): ModuleWithProviders<ReduxStoreModule> {
        return {
            ngModule: ReduxStoreModule,
            providers: [{ provide: REDUCERS, multi: true, useValue: { state, injector, dependencies } }]
        };
    }

    constructor(redux: NgRedux<Object>,
                @Inject(REDUCERS) reducers: any[],
                @Inject(MIDDLEWARES) middlewares: any[],
                @Inject(INITIAL_STATES) initialStates: Object[],
                private injector: Injector,
                private reduxUtils: ReduxUtils
    ) {
        const reducer = this.combineReducers(reducers);
        const enhancers = this.composeEnhancedMiddlewares(middlewares);
        const initialState = initialStates.reduce((prevValue, value) => Object.assign(prevValue, value), {});
        const store = this.reduxUtils.createStore(reducer, initialState, enhancers);

        redux.provideStore(store);
    }

    private combineReducers(reducers) {
        const reducerConfig = reducers.reduce((previousValue, { state, injector, dependencies }) => {
            const reducer = injector ? injector(dependencies) : {};

            return { ...previousValue, [state]: reducer };
        }, {});

        return this.reduxUtils.combineReducers(reducerConfig);
    }

    private composeEnhancedMiddlewares(middlewares): StoreEnhancer<Object> {
        const middlewareExecutors = middlewares.reduce((middlewares, middleware) => {
            if (middleware) {
                middlewares.push(this.injector.get<any>(middleware).executor);
            }

            return middlewares;
        }, [ thunk ]);
        const middlewaresEnhancer = this.reduxUtils.applyMiddleware(...middlewareExecutors);

        const enhancerComposer = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || this.reduxUtils.compose;

        return <StoreEnhancer<Object>>enhancerComposer(middlewaresEnhancer);
    }
}
