import { Pipe, PipeTransform } from '@angular/core';
import { DatexPipe } from './datex.pipe';

@Pipe({
    name: 'videoDuration',
    pure: false
})
export class VideoDurationPipe implements PipeTransform {
    public constructor(private datePipe: DatexPipe) {
    }

    transform(seconds: number | null): string | null {
        if (null === seconds) {
            return;
        }

        const milliseconds = 1000 * seconds;
        const date = new Date(milliseconds);

        return this.datePipe.transform(date, 'm:ss');
    }
}
