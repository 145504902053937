import { Injectable } from '@angular/core';

@Injectable()
export class FakeAwsAuthClass {
    private _config = {};

    public getModuleName() {
        return 'Auth';
    }

    public async currentAuthenticatedUser(): Promise<any> {
        return Promise.resolve(null);
    }

    public async currentSession(): Promise<any> {
        return Promise.resolve(undefined);
    }

    public configure(config) {
        if (!config) {
            return this._config || {};
        }

        this._config = config;
    }
}
