import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { StoreProvider } from '../../../../redux-store/providers';
import {
    createActionContentList,
    createActionContentListError,
    createActionContentListSuccess,
    createActionResetActivePlaylist,
    createActionSetActivePlaylist
} from '../../redux/actions';

import { ContentRepository } from '../../../../shared/services/content/content-repository';
import { Context } from '../../../../shared/user-context/context';

import { Content, Playlist } from '../../../../core/models';

@Injectable()
export class SuggestedPlaylistResolver implements Resolve<any> {
    public static readonly MAX_SUGGESTED_CONTENT_ITEMS = 8;

    constructor(private contentRepository: ContentRepository,
                private store: StoreProvider,
                private userContext: Context) {
    }

    resolve(): Observable<any> {
        this.store.dispatch(createActionContentList());
        this.store.dispatch(createActionResetActivePlaylist());

        const playlist = this.suggestedPlaylist();

        if (undefined === playlist) {
            return of(true);
        }

        this.store.dispatch(createActionSetActivePlaylist(playlist));

        return this.contentRepository.fetchByPlaylistId(
            playlist.id,
            'dateUpdated,desc',
            SuggestedPlaylistResolver.MAX_SUGGESTED_CONTENT_ITEMS,
            this.userContext.get()
        ).pipe(
            tap((content: Content[]) => this.store.dispatch(createActionContentListSuccess(content))),
            catchError(error => this.handleError(error))
        );
    }

    private handleError(error) {
        this.store.dispatch(createActionContentListError(error));

        return EMPTY;
    }

    private suggestedPlaylist(): Playlist {
        const { playlists } = this.store.getState().trainingPagesClient;

        return playlists.find(playlist => playlist.id === Number(this.store.getState().shared.settings.suggestedPlaylistId));
    }
}
