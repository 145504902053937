import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SimpleLink } from '../../../core/models';

import { Settings } from '../../redux/interfaces';
import { StoreProvider } from '../../../redux-store/providers';

import { LinkClicked } from '../../../modules/training-pages-client/event/link-clicked/linkClicked';

@Component({
    selector: 'app-adopt-useful-link-list',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class UsefulLinkListComponent implements OnInit, OnDestroy {
    @Input() public usefulLinks: SimpleLink[] = [];

    public settings: Settings;

    private boundStates = {
        settings: 'shared/settings',
    };

    constructor(private store: StoreProvider,
                private changeDetector: ChangeDetectorRef,
                private linkClicked: LinkClicked
    ) {
    }

    public ngOnInit() {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));
    }

    public onStateChanged(state: string, value: any): void {
        if (state === this.boundStates.settings) {
            this.updateProperty('settings', value);
        }
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    public dispatchLinkClickedEvent(clickedLink: SimpleLink) {
        const linkIndex = this.usefulLinks.indexOf(clickedLink);

        this.linkClicked.linkName = clickedLink.text;
        this.linkClicked.linkDestination = clickedLink.url;

        if (linkIndex !== -1) {
            this.linkClicked.linkPosition = linkIndex + 1;
        }

        const event = this.linkClicked.buildEvent();

        this.linkClicked.dispatch(event);
    }

    private updateProperty(property, value) {
        this[ property ] = value;

        this.changeDetector.detectChanges();
    }
}
