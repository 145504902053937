import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { EMPTY, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { StoreProvider } from '../../../../redux-store/providers';
import { PartialSettings, Settings } from '../../../../shared/redux';
import { SettingsRepository } from '../../../../shared/services/settings/settings-repository';

import {
    createActionSettingsListSuccess,
    createActionSettingsListError,
} from '../../../../shared/redux';
import { SettingsResponse } from '../../../../shared/models/settings-response';

@Injectable()
export class SettingsResolver implements Resolve<Settings> {
    constructor(private repository: SettingsRepository,
                private store: StoreProvider) {
    }

    resolve(): Observable<any> {
        return this.repository.fetchAll().pipe(
            tap((settingsResponse: SettingsResponse) => {
                const partialSettings = this.mapSettingsResponse(settingsResponse);

                this.store.dispatch(createActionSettingsListSuccess(partialSettings));
            }),
            catchError(error => {
                this.handleError(error);

                return EMPTY;
            })
        );
    }

    private handleError(error) {
        this.store.dispatch(createActionSettingsListError(error));

        return error;
    }

    private mapSettingsResponse({ system }: SettingsResponse): PartialSettings {
        const keyMap = {
            roleIds: 'training_pages_role_ids',
            languageIds: 'training_pages_language_ids',
            locationIds: 'training_pages_location_ids',
            suggestedPlaylistId: 'training_pages_suggested_playlist_id',
            logoUrl: 'training_pages_logo_url',
            primaryColor: 'training_pages_primary_color',
            backgroundUrl: 'training_pages_background_url',
            backgroundColor: 'training_pages_background_color',
            homepageLinks: 'training_pages_homepage_links',
            adoptSnippet: 'adopt_in_training_pages',
        };

        return Object
            .entries(keyMap)
            .filter(this.settingExists(system))
            .map(this.mapSettingToExpectedKey(system))
            .map(this.parseSetting())
            .reduce(this.objectFromKeyValue, {});
    }

    private settingExists(system): ([ , responseKey ]) => any {
        return ([ , responseKey ]) => system.hasOwnProperty(responseKey) && undefined !== system[responseKey];
    }

    private mapSettingToExpectedKey(system): ([expectedKey, responseKey]) => any[] {
        return ([ expectedKey, responseKey ]) => [ expectedKey, system[responseKey] ];
    }

    private parseSetting(): ([expectedKey, responseKey]) => any[] {
        return ([ expectedKey, responseKey ]) => {
            if (expectedKey === 'adoptSnippet') {
                try {
                    return [ expectedKey, JSON.parse(responseKey) ];
                } catch (e) {
                    console.error('Adopt Snippet settings is not valid JSON', e.message || e);

                    return [ expectedKey, { enabled: false } ];
                }
            }

            return [ expectedKey, responseKey ];
        };
    }

    private objectFromKeyValue(referenceObject, [ key, value ]): Object {
        referenceObject[key] = value;

        return referenceObject;
    }
}
