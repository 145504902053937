import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Playlist } from '../../../core/models/';
import { Slug } from '../../router/slug';
import { PlaylistResponse } from '../../../core/models/playlist/playlist-response';
import { UserContext } from '../../../core/models';
import { HttpParameters } from '../../interface/http-parameters';
import { Transformable } from '../../transformer/transformable';
import { HttpParameterTransformer } from '../../di/provider/http-parameter-transformer';
import { KeyedByString } from '../../interface/keyed-by-string';
import { AppGatewayUrlService } from '../app-gateway-url/service';

@Injectable()
export class PlaylistRepository {
    public constructor(private http: HttpClient,
                       private slug: Slug,
                       private appGatewayUrlService: AppGatewayUrlService,
                       @Inject(HttpParameterTransformer) private httpParameterTransformer: Transformable<KeyedByString, HttpParameters>) {}

    public fetchAll(context?: UserContext): Observable<Playlist[]> {
        const params = this.httpParameterTransformer.transform({ ...context });

        return this.http.get<PlaylistResponse[]>(`${ this.appGatewayUrlService.url }/playlists`, { params })
            .pipe(
                map((playlists: PlaylistResponse[]): Playlist[] => this.mapPlaylistResponse(playlists))
            );
    }

    private mapPlaylistResponse(playlists: PlaylistResponse[]): Playlist[] {
        return playlists.map(playlist => {
            // TODO: Remove mapping when slug is sent in api response
            const slug: any = this.slug.create(playlist.name, playlist.id);

            return {
                ...playlist,
                showOnMobile: playlist.showOnMobile === 1,
                showOnDesktop: playlist.showOnDesktop === 1,
                slug,
            };
        });
    }
}
