import { ContextItem, ContextResponse, ContextSelfSelectClient } from '@applearn/context-self-select/dist';

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StoreProvider } from '../../../redux-store/providers';
import { ContextSelfSelectInjectionToken } from '../../di/provider/context-self-select';
import { createActionSetSelfSelectContext, createActionSetUserContextProvidedBy } from '../../redux';
import { concat, Observable } from 'rxjs';
import { take, skipWhile, takeLast } from 'rxjs/operators';
import { UserContextProvidedBy } from '../../../core/enums/UserContextProvidedBy';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class SelfSelectDecorator {
    private static SETTING_LOAD_PATH = 'shared/settingsLoaded';
    private static COLOUR_STATE_PATH = 'shared/settings/primaryColor';
    private static COLOUR_CONFIG_KEY = 'primaryColor';

    constructor(private store: StoreProvider,
                private router: Router,
                @Inject(ContextSelfSelectInjectionToken) private client: ContextSelfSelectClient) {
    }

    public display(): Subscription {
        return this.settingsObservable().subscribe(colour => {
            this.setPrimaryColour(colour as string);
            this.renderForm(document.body);
            this.onSave().then(context => this.postSave(context));
        });
    }

    public onSave(): Promise<ContextResponse> {
        return this.client.onSave();
    }

    public renderForm(element: HTMLElement): Promise<void> {
        return this.client.renderForm(element);
    }

    public getValue(fromKey: string): ContextItem {
        return this.client.getValue(fromKey);
    }

    public getValues(): ContextResponse {
        return this.client.getValues();
    }

    public canDisplay(): Promise<boolean> {
        return this.client.canDisplay();
    }

    private settingsObservable(): Observable<string> {
        return concat(this.settingsHaveLoaded(), this.primaryColourSetting())
            .pipe(takeLast(1)) as Observable<string>;
    }

    private postSave(context: ContextResponse): void {
        this.store.dispatch(createActionSetSelfSelectContext(context));
        this.store.dispatch(createActionSetUserContextProvidedBy(UserContextProvidedBy.SELF_SELECT));

        this.router.navigate([ '/' ]);
    }

    private setPrimaryColour(colour: string): void {
        if ('' !== colour) {
            this.client.setConfigValue(SelfSelectDecorator.COLOUR_CONFIG_KEY, colour);
        }
    }

    private settingsHaveLoaded(): Observable<boolean> {
        return this.store
            .select<boolean>(SelfSelectDecorator.SETTING_LOAD_PATH)
            .pipe(skipWhile(val => val === false), take(1));
    }

    private primaryColourSetting(): Observable<string> {
        return this.store
            .select<string>(SelfSelectDecorator.COLOUR_STATE_PATH).pipe(take(1));
    }
}
