import { InjectionToken } from '@angular/core';

export const WindowInjectionToken = new InjectionToken('WindowInjectionToken');

export function windowFactory(): Window {
    return window;
}

export const WindowProvider = {
    provide: WindowInjectionToken,
    useFactory: windowFactory
};
