import { FactoryProvider, InjectionToken } from '@angular/core';
import Amplify from '@aws-amplify/core';

export const AmplifyToken = new InjectionToken('Amplify');

export function amplifyFactory(): typeof Amplify {
    return Amplify;
}

export const AmplifyProvider: FactoryProvider = {
    provide: AmplifyToken,
    useFactory: amplifyFactory,
};
