import { Injectable } from '@angular/core';
import { UserContext as UserContextInterface } from '../../../core/models';

@Injectable()
export class UnmappedContext {
    public static readonly UNMAPPED = -1;

    public all(): UserContextInterface {
        return {
            roleIds: [ UnmappedContext.UNMAPPED ],
            languageIds: [ UnmappedContext.UNMAPPED ],
            locationIds: [ UnmappedContext.UNMAPPED ]
        };
    }
}
