import { Injectable } from '@angular/core';
import { ContentRepository } from '../../../../shared/services/content/content-repository';
import {
    createActionContentList,
    createActionContentListError,
    createActionContentListSuccess
} from '../actions/content';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class ContentThunk {
    constructor(private repository: ContentRepository,
                private userContext: Context) {
    }

    public fetchByPlaylistId(playlistId: number, limit?: number) {
        return (dispatch) => {
            dispatch(createActionContentList());

            this.repository
                .fetchByPlaylistId(playlistId, 'dateUpdated,desc', limit, this.userContext.get()).subscribe(
                content => dispatch(createActionContentListSuccess(content)),
                error => dispatch(createActionContentListError(error))
            );
        };
    }
}
