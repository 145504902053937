import { Inject, Injectable } from '@angular/core';
import { WindowInjectionToken } from '../di/provider/window';
import { environment } from '../../../environments/environment';
import { AdoptPlayerBootstrap } from '../providers/adopt-player-bootstrap';
import { AdoptPlayerHeaders } from '../interface/adopt-player-headers';
import { AppGatewayUrlService } from '../services/app-gateway-url/service';

@Injectable()
export class AdoptPlayer {
    public static TAG_NAME = 'adopt-training-pages-player';

    constructor(@Inject(AdoptPlayerBootstrap) private setUpAdoptPlayerComponent: Function,
                @Inject(WindowInjectionToken) private window: Window,
                private appGatewayUrlService: AppGatewayUrlService) {
    }

    public bootstrapCustomElement(headersCallback: () => AdoptPlayerHeaders): void {
        if (this.window.customElements && undefined !== this.window.customElements.get(AdoptPlayer.TAG_NAME)) {
            return;
        }

        this.setUpAdoptPlayerComponent({
            apiAddress: this.appGatewayUrlService.url,
            cdnAddress: environment.playersCdnPath,
            application: environment.applicationUuid,
            headersCallback: headersCallback,
            tagName: AdoptPlayer.TAG_NAME,
            cloudEdgeProxyUrl: environment.cloudEdgeProxyUrl,
        });
    }
}
