import { InitialState } from '../interfaces';

export const rootInitialState: InitialState = {
    filteredContent: [],
    activePlaylist: null,
    activeContentItem: null,
    browseMenu: {
        active: false
    },
    playlists: [],
};
