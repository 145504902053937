import { Inject, Injectable } from '@angular/core';

import { SharedEventClient } from 'src/app/shared/event/sharedEventClient';
import { StoreProvider } from 'src/app/redux-store/providers/index';
import { EventDispatcher } from 'src/app/shared/event/dispatch/EventDispatcher';
import { WindowInjectionToken } from '../../../../shared/di/provider/window';
import { Content, Playlist } from '../../../../core/models';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class ContentViewed extends SharedEventClient {
    protected eventType = 'Content Viewed';

    public contentItem: Content;
    public playlist: Playlist;

    constructor(
        @Inject(StoreProvider) protected store: StoreProvider,
        @Inject(EventDispatcher) protected eventDispatcher: EventDispatcher,
        @Inject(WindowInjectionToken) protected window: Window,
        @Inject(Context) protected context: Context,
    ) {
        super(store, eventDispatcher, window, context);
    }

    public eventDetail() {
        const content = undefined === this.contentItem
            ? {}
            : {
                content_id: this.contentItem.id,
                content_name: this.contentItem.nameEnglish,
                content_display_name: this.contentItem.name,
                content_type: this.contentItem.typeName,
                content_location: this.contentItem.link,
                content_language: 'null' === this.contentItem.languageName ? null : this.contentItem.languageName,
                content_category: 'null' === this.contentItem.categoryName ? null : this.contentItem.categoryName,
                content_added_on: this.contentItem.createdOn,
                content_updated_on: this.contentItem.updatedOn,
                content_file_duration: this.contentItem.contentFileDuration,
                content_file_size: this.contentItem.contentFileSize
            };

        const playlist = undefined === this.playlist
            ? {}
            : {
                playlist_name: this.playlist.name,
                total_content_in_playlist: this.playlist.totalActiveContentItems
            };

        return { ...content, ...playlist };
    }
}
