import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { StoreProvider } from '../../../../redux-store/providers';
import { IOnStateChanged } from '../../../../redux-store/interfaces';
import { createActionBrowseMenuToggle } from '../../redux/actions/browse-menu';

import { Playlist } from '../../../../core/models';
import { NavigationSelected } from '../../event/navigation-selected/navigationSelected';

@Component({
    selector: 'app-browse-menu-list',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class BrowseMenuListComponent implements OnInit, OnDestroy, IOnStateChanged {
    public playlists: Playlist[] = [];

    private boundStates = {
        playlists: 'trainingPagesClient/playlists',
    };

    public constructor(
        private store: StoreProvider,
        private changeDetector: ChangeDetectorRef,
        private navigationSelected: NavigationSelected) {
    }

    public ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));
    }

    public onStateChanged(state, value): void {
        if (state === this.boundStates.playlists) {
            this.updateProperty('playlists', value);
        }
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    public closeMenu(): void {
        this.store.dispatch(createActionBrowseMenuToggle());
    }

    private updateProperty(property, v): void {
        this[ property ] = v;

        this.changeDetector.detectChanges();
    }

    public dispatchNavigationSelectedEvent(clickedPlaylist: Playlist): void {
        this.navigationSelected.playlistName = clickedPlaylist.name;
        this.navigationSelected.playlistPosition = this.playlists.findIndex(playlist => playlist.id === clickedPlaylist.id) + 1;

        const event = this.navigationSelected.buildEvent();

        this.navigationSelected.dispatch(event);
    }
}
