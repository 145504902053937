import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { style, transition, trigger, animate, query, stagger } from '@angular/animations';

import { StoreProvider } from '../../../../redux-store/providers';
import { IOnStateChanged } from '../../../../redux-store/interfaces';

import { Content, Playlist } from '../../../../core/models';
import { ContentListItemClicked as ContentClickedType } from '../../../../core/models/content/contentListItemClicked';

import { ContentClicked } from '../../event/content/ContentClicked';

@Component({
    selector: 'app-content-list',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ],
    animations: [
        trigger('listAnimation', [
            transition('* => *', [
                query(':enter', [
                    style({ opacity: 0 }),
                    stagger(200, [
                        animate('0.5s', style({ opacity: 1 }))
                    ])
                ], { optional: true })
            ])
        ])
    ],
})
export class ContentListComponent implements OnInit, OnDestroy, IOnStateChanged {
    @Input() public contentItems: Content[];
    @Output() contentItemClicked: EventEmitter<ContentClickedType> = new EventEmitter();

    public playlists: Playlist[] = [];

    private boundStates = {
        playlists: 'trainingPagesClient/playlists'
    };

    public constructor(private router: Router,
        private store: StoreProvider,
        private changeDetector: ChangeDetectorRef,
        private contentClickedEvent: ContentClicked) {
    }

    public ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));

        this.contentItemClicked.subscribe(({ content, contentItemPosition }) => {
            this.dispatchContentClickedEvent(content, contentItemPosition);
            this.router.navigate(['/content/', content.slug]);
        });
    }

    public onStateChanged(state: string, value: any): void {
        switch (state) {
            case this.boundStates.playlists:
                this.updateProperty('playlists', value);
                break;
        }
    }

    private updateProperty(property, value) {
        this[ property ] = value;

        this.changeDetector.detectChanges();
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    private dispatchContentClickedEvent(content: Content, contentItemPosition: number) {
        const playlist: Playlist = this.playlists
            .find(playlist => playlist.id === content.playlistId);

        this.contentClickedEvent.contentItem = content;
        this.contentClickedEvent.playlist = playlist;
        this.contentClickedEvent.contentItemPosition = ++contentItemPosition;

        const event = this.contentClickedEvent.buildEvent();

        this.contentClickedEvent.dispatch(event);
    }

    public emitClickedContentItem(content: Content, contentItemPosition: number) {
        this.contentItemClicked.emit({ content, contentItemPosition });
    }
}
