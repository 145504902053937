import { Injectable } from '@angular/core';
import { StoreProvider } from '../../../redux-store/providers';
import { concat, Observable } from 'rxjs';
import { skipWhile, take, takeLast } from 'rxjs/operators';
import { AdoptSnippet } from '../../redux';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class AdoptSnippetLoader {
    private static SETTING_LOAD_PATH = 'shared/settingsLoaded';
    private static ADOPT_SNIPPET_ENABLED_PATH = 'shared/settings/adoptSnippet';

    constructor(private store: StoreProvider) {
    }

    public inject(): Subscription {
        return this.settingsObservable()
            .subscribe(adoptSnippetSettings => {
                if (adoptSnippetSettings.enabled === false || adoptSnippetSettings.environmentIdentifier === undefined) {
                    return;
                }

                document.body.appendChild(this.snippetScript(adoptSnippetSettings as AdoptSnippet));
            });
    }

    private settingsObservable(): Observable<AdoptSnippet> {
        return concat(this.settingsHaveLoaded(), this.adoptSnippetSettings())
            .pipe(takeLast(1)) as Observable<AdoptSnippet>;
    }

    private settingsHaveLoaded(): Observable<boolean> {
        return this.store
            .select<boolean>(AdoptSnippetLoader.SETTING_LOAD_PATH)
            .pipe(
                skipWhile(val => val === false),
                take(1)
            );
    }

    private adoptSnippetSettings(): Observable<AdoptSnippet> {
        return this.store
            .select<AdoptSnippet>(AdoptSnippetLoader.ADOPT_SNIPPET_ENABLED_PATH).pipe(take(1));
    }

    private snippetScript(adoptSnippetSettings: AdoptSnippet): HTMLScriptElement {
        const script = document.createElement('script');
        script.id = 'applearn';
        script.type = 'text/javascript';
        script.async = true;
        script.src = environment.adoptSnippetUrl;
        script.dataset.client = (adoptSnippetSettings as AdoptSnippet).environmentIdentifier;

        return script;
    }
}
