<h2 class="text-center text-white" [ngPlural]="searchResults.length"
    role="heading" aria-level="2">
    {{ searchResults.length }}
    <ng-template ngPluralCase="=1">Result</ng-template>
    <ng-template ngPluralCase="other">Results</ng-template>
</h2>

<app-content-list
        *ngIf="0 !== searchResults.length"
        [contentItems]="searchResults"
        (contentItemClicked)="dispatchSearchResultSelectedEvent($event)"
></app-content-list>
