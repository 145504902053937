import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { StoreProvider } from '../../../../redux-store/providers';
import { Content } from '../../../../core/models';
import { SearchResultSelected } from '../../event/search/searchResultSelected';
import { ContentListItemClicked } from '../../../../core/models/content/contentListItemClicked';

@Component({
    selector: 'app-search-results-view',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class SearchResultsViewComponent implements OnInit, OnDestroy {
    public searchResults: Content[] = [];
    private filteredContent: Subscription;

    public constructor(private store: StoreProvider,
                       private searchResultSelected: SearchResultSelected,
                       private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.filteredContent = this.store.select('trainingPagesClient/filteredContent')
            .subscribe((contentItems: Content[]) => this.searchResults = contentItems);
    }

    public dispatchSearchResultSelectedEvent({ content, contentItemPosition }: ContentListItemClicked): void {
        this.searchResultSelected.searchTerm = this.route.snapshot.queryParams['query'];
        this.searchResultSelected.searchResults = this.searchResults.map(searchResults => searchResults.name);
        this.searchResultSelected.searchSelected = content.nameEnglish === '' ? content.name : content.nameEnglish;
        this.searchResultSelected.searchSelectedPosition = contentItemPosition;

        const event = this.searchResultSelected.buildEvent();

        this.searchResultSelected.dispatch(event);
    }

    public ngOnDestroy(): void {
        this.filteredContent.unsubscribe();
    }
}
