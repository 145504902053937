import { Injectable } from '@angular/core';

import { ContentThunk } from './content';

import { createActionSetActivePlaylist } from '../actions/playlist';

@Injectable()
export class PlaylistThunk {
    constructor(private contentThunk: ContentThunk) {
    }

    public setActivePlaylistFromSlug(slug: string) {
        return (dispatch, getState) => {
            const { playlists } = getState().trainingPagesClient;
            const matchedPlaylist = playlists.find(playlist => playlist.slug === slug);

            if (undefined !== matchedPlaylist) {
                dispatch(createActionSetActivePlaylist(matchedPlaylist));
                dispatch(this.contentThunk.fetchByPlaylistId(matchedPlaylist.id));
            }
        };
    }

    public setActivePlaylistFromId(id: number) {
        return (dispatch, getState) => {
            const { playlists } = getState().trainingPagesClient;
            const matchedPlaylist = playlists.find(playlist => playlist.id === id);

            if (undefined !== matchedPlaylist) {
                dispatch(createActionSetActivePlaylist(matchedPlaylist));
            }
        };
    }
}
