import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HttpErrorComponent } from '../components';
import { UnauthorizedComponent } from '../components/unauthorized/component';
import { PageNotFoundComponent } from '../components/page-not-found/component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: '',
                component: HttpErrorComponent,
                children: [
                    {
                        component: UnauthorizedComponent,
                        path: 'unauthorized',
                        data: { pageTitle: 'Unauthorized' }
                    },
                    {
                        path: '**',
                        component: PageNotFoundComponent,
                        data: { pageTitle: 'Page Not Found' }
                    },
                    {
                        path: 'page-not-found',
                        component: PageNotFoundComponent,
                        data: { pageTitle: 'Page Not Found' }
                    }
                ]
            }
        ]),
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class ErrorRoutingModule {
}
