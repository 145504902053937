import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { ContentSignature } from '../../../../shared/services/content/content-signature';

@Injectable()
export class SignedCookieResolver implements Resolve<void> {
    constructor(private contentSignature: ContentSignature) {
    }

    resolve(): Observable<void> {
        return this.contentSignature.fetch();
    }
}
