import { IAction } from '../../../../../redux-store/interfaces';
import {
    PLAYLIST_LIST,
    PLAYLIST_LIST_ERROR,
    PLAYLIST_LIST_SUCCESS,
    RESET_ACTIVE_PLAYLIST,
    SET_ACTIVE_PLAYLIST,
} from './types';

import { Playlist } from '../../../../../core/models';

export function createActionPlaylistList(): IAction {
    return {
        type: PLAYLIST_LIST
    };
}

export function createActionPlaylistListSuccess(playlists: Playlist[]): IAction {
    return {
        type: PLAYLIST_LIST_SUCCESS,
        payload: playlists
    };
}

export function createActionPlaylistListError(error): IAction {
    return {
        type: PLAYLIST_LIST_ERROR,
        payload: error
    };
}

export function createActionResetActivePlaylist(): IAction {
    return {
        type: RESET_ACTIVE_PLAYLIST
    };
}

export function createActionSetActivePlaylist(playlist: Playlist): IAction {
    return {
        type: SET_ACTIVE_PLAYLIST,
        payload: playlist
    };
}
