import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared/module';
import { EventDispatcher } from './shared/event/dispatch/EventDispatcher';

import { AppComponent } from './app.component';
import { TrainingPagesClientModule } from './modules/training-pages-client/module';
import { ErrorModule } from './error/module';
import { AdoptSnippetLoader } from './shared/services/adopt-snippet/adopt-snippet-loader';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        RouterModule.forRoot([], { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' }),
        BrowserModule,
        HttpClientModule,
        TrainingPagesClientModule,
        ErrorModule,
        SharedModule
    ],
    providers: [
        EventDispatcher,
        AdoptSnippetLoader
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {}
