import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IOnStateChanged } from '../../../../redux-store/interfaces';
import { StoreProvider } from '../../../../redux-store/providers';

import { Settings } from '../../../../shared/redux';
import { TrainingPagesLoaded } from '../../../../shared/event/loaded/trainingPagesLoaded';

@Component({
    selector: 'app-container',
    templateUrl: './template.html',
    styleUrls: ['./styles.scss'],
})
export class ContainerComponent implements OnInit, OnDestroy, IOnStateChanged {
    public browseMenuIsOpen = false;
    public settings: Settings;

    private boundStates = {
        browserMenu: 'trainingPagesClient/browseMenu',
        settings: 'shared/settings'
    };

    public constructor(
        private store: StoreProvider,
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private trainingPagesLoaded: TrainingPagesLoaded) {
    }

    ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));

        this.dispatchTrainingPagesLoadedEvent();
    }

    onStateChanged(state: string, value: any): void {
        switch (state) {
            case this.boundStates.settings:
                this.updateProperty('settings', value);
                this.setBackground();
                break;
            case this.boundStates.browserMenu:
                this.browseMenuIsOpen = value.active;
                break;
        }
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    private setBackground() {
        this.document.body.style.backgroundColor = this.settings.backgroundColor;

        if (null !== this.settings.backgroundUrl) {
            this.document.body.style.backgroundImage = `url(${this.settings.backgroundUrl})`;
        }
    }

    private updateProperty(property, value) {
        this[ property ] = value;

        this.changeDetector.detectChanges();
    }

    private dispatchTrainingPagesLoadedEvent() {
        const event = this.trainingPagesLoaded.buildEvent();

        this.trainingPagesLoaded.dispatch(event);
    }
}
