import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { NavbarComponent, UsefulLinkListComponent } from './components';
import { AmplifyProvider } from './di/provider/amplify-provider';

import { PlaylistRepository } from './services/playlist/playlist-repository';
import { SettingsRepository } from './services/settings/settings-repository';
import { ContentRepository } from './services/content/content-repository';

import { ReduxStoreModule } from '../redux-store/module';
import { sharedInitialState, sharedReducer } from './redux/reducers';
import { contextReducer } from './redux/reducers/context';

import { ContextCreator } from './context/creator';

import { VideoDurationPipe } from './pipes/video-duration.pipe';
import { DatexPipe } from './pipes/datex.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { StringCastHttpParametersTransformer } from './transformer/transformers/string-cast-http-parameters-transformer';
import { UndefinedParameterTransformer } from './transformer/transformers/undefined-parameter-transformer';

import { HttpParameterTransformerProvider } from './di/provider/http-parameter-transformer';
import { ContextSelfSelectProvider } from './di/provider/context-self-select';
import { AsyncSchedulerProvider } from './di/provider/async-provider';
import { UserAgentParserProvider } from './di/provider/user-agent-parser';
import { NavigatorProvider } from './di/provider/navigator';
import { WindowProvider } from './di/provider/window';
import { UserContextProvider } from './di/provider/user-context';
import { FingerprintProvider } from './di/provider/fingerprint-provider';

import { UnmappedContext } from './user-context/unmapped-context/unmapped-context';
import { CompanyContext } from './user-context/company-context/company-context';
import { Context } from './user-context/context';

import { SelfSelectContextHandler } from './handlers/self-select-context-handler';
import { EnvironmentRepository } from './services/authentication/environment-repository.service';
import { FakeAwsAuthClass } from './services/auth/fake-aws-auth-class.service';
import { AmplifyHubProvider } from './di/provider/hub-provider';
import { AmplifySetup } from './services/amplify/amplifySetup';
import { AmplifyGuard } from './guards/amplify-guard';
import { AuthGuard } from './guards/auth-guard';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UnauthenticatedHttpClient } from './providers/unauthenticated-http-client';
import { PageTitle } from './services/page-title/page-title';
import { SelfSelectDecorator } from './services/self-select/SelfSelectDecorator';
import { ContentSignature } from './services/content/content-signature';
import { AdoptOrigin } from './interceptors/adopt-origin';
import { AdoptPlayerBootstrapProvider } from './providers/adopt-player-bootstrap';
import { AdoptPlayer } from './player/adopt-player';
import { AmplifyCognitoTokenRefresh } from './services/auth/amplify-cognito-token-refresh';
import { AppGatewayUrlService } from './services/app-gateway-url/service';

@NgModule({
    declarations: [
        NavbarComponent,
        UsefulLinkListComponent,
        DatexPipe,
        VideoDurationPipe,
        SafePipe,
    ],
    imports: [
        RouterModule,
        ReduxStoreModule.registerReducer('shared', sharedReducer, { contextReducer }),
        ReduxStoreModule.registerInitialState({ shared: sharedInitialState }),
        HttpClientModule,
        CommonModule
    ],
    providers: [
        ContentRepository,
        ContentSignature,
        SettingsRepository,
        PlaylistRepository,
        EnvironmentRepository,
        UserAgentParserProvider,
        NavigatorProvider,
        WindowProvider,
        ContextCreator,
        DatexPipe,
        VideoDurationPipe,
        SafePipe,
        AsyncSchedulerProvider,
        StringCastHttpParametersTransformer,
        UndefinedParameterTransformer,
        HttpParameterTransformerProvider,
        AmplifySetup,
        UnmappedContext,
        CompanyContext,
        ContextSelfSelectProvider,
        Context,
        SelfSelectContextHandler,
        UserContextProvider,
        FingerprintProvider,
        AmplifyGuard,
        AuthGuard,
        AuthenticatorService,
        FakeAwsAuthClass,
        AmplifyHubProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdoptOrigin,
            multi: true
        },
        UnauthenticatedHttpClient,
        PageTitle,
        SelfSelectDecorator,
        AdoptPlayerBootstrapProvider,
        AdoptPlayer,
        AmplifyCognitoTokenRefresh,
        AppGatewayUrlService,
        AmplifyProvider,
    ],
    exports: [
        NavbarComponent,
        UsefulLinkListComponent,
        DatexPipe,
        VideoDurationPipe,
        SafePipe,
    ]
})
export class SharedModule {}
