import { Injectable } from '@angular/core';
import { IOnStateChange, IOnStateChanged } from '../../interfaces';

import { NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { RootState } from '../../interfaces/root-state';

@Injectable()
export class StoreProvider {
    constructor(private redux: NgRedux<RootState>) {}

    private subscriptions = [];

    public lazyLoadSelect<T>(statePath: string, initialState: T, thunk: Function): Observable<T> {
        const stateSelect = this.select<T>(statePath);
        let times = 0;

        return stateSelect
            .pipe(
                tap(val => {
                    times++;

                    if (initialState === val && times === 1) {
                        this.dispatch(thunk);
                    }
                }),
                filter(val => initialState !== val || times === 2),
                take(1)
            );
    }

    select<T>(targetState: string): Observable<T>  {
        const path = targetState.split('/');

        return this.redux.select<T>(path);
    }

    bind(targetSubject: IOnStateChange & IOnStateChanged, targetState: string) {
        let from: any;

        const sub = this.select(targetState).subscribe((to: any) => {
            if (targetSubject.onStateChange) {
                targetSubject.onStateChange(targetState, from, to);
            }

            from = to;

            if (targetSubject.onStateChanged) {
                targetSubject.onStateChanged(targetState, to);
            }
        });

        this.subscriptions.push({ subject: targetSubject, state: targetState, sub });
    }

    unbind(targetSubject: any, targetState: string) {
        this.subscriptions = this.subscriptions.filter(({ subject, state, sub }) => {
            let keep = true;

            if (subject === targetSubject && state === targetState) {
                sub.unsubscribe();
                keep = false;
            }

            return keep;
        });
    }

    dispatch(action: any) {
        this.redux.dispatch(action);
    }

    getState(): RootState {
        return this.redux.getState();
    }
}
