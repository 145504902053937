import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Content } from '../../../../core/models';

import { StoreProvider } from '../../../../redux-store/providers';
import { createActionResetActivePlaylist, createActionSetActivePlaylist } from '../../redux/actions/playlist';
import { ContentRepository } from '../../../../shared/services/content/content-repository';
import { createActionContentList, createActionContentListError, createActionContentListSuccess } from '../../redux/actions/content';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class ActivePlaylistResolver implements Resolve<Content[]> {
    constructor(private contentRepository: ContentRepository,
                private store: StoreProvider,
                private userContext: Context,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Content[]> {
        const { playlists } = this.store.getState().trainingPagesClient;
        const slug = route.params.playlistSlug;
        const matchedPlaylist = playlists.find(playlist => playlist.slug === slug);

        this.store.dispatch(createActionResetActivePlaylist());

        if (undefined === matchedPlaylist) {
            this.displayPageNotFound();
            return EMPTY;
        }

        this.store.dispatch(createActionSetActivePlaylist(matchedPlaylist));

        return this.fetchByPlaylistId(matchedPlaylist.id);
    }

    private fetchByPlaylistId(playlistId: number): Observable<Content[]> {
        const errorCodes = [ 404 ];

        this.store.dispatch(createActionContentList());

        return this.contentRepository
            .fetchByPlaylistId(playlistId, 'dateUpdated,desc', undefined, this.userContext.get())
            .pipe(
                tap(
                    content => this.store.dispatch(createActionContentListSuccess(content)),
                    error => {
                        this.store.dispatch(createActionContentListError(error));

                        if (error instanceof HttpErrorResponse && errorCodes.includes(error.status)) {
                            this.displayPageNotFound();
                            return EMPTY;
                        }
                    }
                )
            );
    }

    private displayPageNotFound(): void {
        this.router.navigate(['/page-not-found'], { skipLocationChange: true });
    }
}
