import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

import { Event } from '../types/Event';
import { EventDispatchable } from './EventDispatchable';
import { StoreProvider } from '../../../redux-store/providers';

@Injectable()
export class EventDispatcher implements EventDispatchable {
    constructor(
        protected http: HttpClient,
        private store: StoreProvider,
    ) {
    }

    dispatch(event: Event): void {
        const body = {
            Data: {
                event: event
            },
            PartitionKey: 1
        };
        const companyRoutingIdentifier = this.store.getState().shared.companyRoutingIdentifier;

        // TODO: will be removed in the phase 2 multi region work in favour of URL always be prepended
        const subdomain = companyRoutingIdentifier ? `${companyRoutingIdentifier}.` : '';

        this.http.put(
            `https://${subdomain}${environment.eventUrl}/streams/${environment.eventStream}/record`,
            body,
            {
                headers: {
                    token: 'none'
                }
            }
        ).subscribe();
    }
}
