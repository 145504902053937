import { Inject, Injectable } from '@angular/core';

import { Content, Playlist } from 'src/app/core/models';

import { StoreProvider } from 'src/app/redux-store/providers';

import { EventDispatcher } from 'src/app/shared/event/dispatch/EventDispatcher';
import { SharedEventClient } from 'src/app/shared/event/sharedEventClient';
import { WindowInjectionToken } from 'src/app/shared/di/provider/window';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class ContentClicked extends SharedEventClient {
    protected eventType = 'Content Clicked';

    public contentItem: Content;
    public playlist: Playlist;
    public contentItemPosition: number;

    constructor(
        @Inject(StoreProvider) protected store: StoreProvider,
        @Inject(EventDispatcher) protected eventDispatcher: EventDispatcher,
        @Inject(WindowInjectionToken) protected window: Window,
        @Inject(Context) protected context: Context,
    ) {
        super(store, eventDispatcher, window, context);
    }

    public eventDetail(): any {
        const content = undefined === this.contentItem
            ? {}
            : {
                content_id: this.contentItem.id,
                content_name: this.contentItem.nameEnglish,
                content_display_name: this.contentItem.name,
                content_type: this.contentItem.typeName,
                content_added_on: this.contentItem.createdOn,
                content_updated_on: this.contentItem.updatedOn,
            };

        const playlist = undefined === this.playlist
            ? {}
            : {
                playlist_name: this.playlist.name,
                total_content_in_playlist: this.playlist.totalActiveContentItems
            };

        const position = undefined === this.contentItemPosition
            ? {}
            : {
                content_position: this.contentItemPosition,
            };

        return {
            ...content,
            ...playlist,
            ...position,
        };
    }
}
