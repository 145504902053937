import { InjectionToken } from '@angular/core';
import { StringCastHttpParametersTransformer } from '../../transformer/transformers/string-cast-http-parameters-transformer';
import { UndefinedParameterTransformer } from '../../transformer/transformers/undefined-parameter-transformer';
import { Transformer } from '../../transformer/transformer';
import { KeyedByString } from '../../interface/keyed-by-string';
import { HttpParameters } from '../../interface/http-parameters';

export const HttpParameterTransformer = new InjectionToken('HttpParameterTransformer');

export function httpParameterTransformerFactory(): Transformer<KeyedByString, HttpParameters> {
    const transformer = new Transformer<KeyedByString, HttpParameters>();

    transformer.attach(new UndefinedParameterTransformer());
    transformer.attach(new StringCastHttpParametersTransformer());

    return transformer;
}

export const HttpParameterTransformerProvider = {
    provide: HttpParameterTransformer,
    useFactory: httpParameterTransformerFactory,
};
