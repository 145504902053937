import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '../shared/module';
import { ErrorRoutingModule } from './routing/router';
import { HttpErrorComponent } from './components';
import { UnauthorizedComponent } from './components/unauthorized/component';
import { PageNotFoundComponent } from './components/page-not-found/component';
import { ErrorInterceptor } from './interceptors/error.interceptor';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ErrorRoutingModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    declarations: [
        HttpErrorComponent,
        UnauthorizedComponent,
        PageNotFoundComponent
    ]
})
export class ErrorModule {}
