import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { StoreProvider } from '../../../../redux-store/providers';

@Injectable()
export class PlaylistTitleResolver implements Resolve<string> {
    constructor(private store: StoreProvider) {
    }

    resolve(): Observable<string> {
        return this.store.select('trainingPagesClient/activePlaylist/name').pipe(
            filter((name: string) => null !== name),
            take(1)
        );
    }
}
