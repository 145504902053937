import { SharedInitialState } from '../interfaces';
import { contextInitialState } from './context';
import { DefaultSettings } from '../../../settings/defaultSettings';

export const sharedInitialState: SharedInitialState = {
    context: contextInitialState,
    settings: DefaultSettings,
    settingsLoaded: false,
    selfSelectContext: {},
    selfSelectVisibility: undefined,
    cognitoConfiguration: null,
    companyRoutingIdentifier: null,
};
