<nav role="navigation" aria-label="menu">
    <ul>
        <li *ngFor="let playlist of playlists"
            [routerLink]="['/playlist/', playlist.slug]"
            [routerLinkActive]="'is-active'"
            [class.hide-on-mobile]="!playlist.showOnMobile"
            [class.hide-on-desktop]="!playlist.showOnDesktop"
            (click)="closeMenu(); dispatchNavigationSelectedEvent(playlist);"
            [title]="playlist.name">
            <span>{{ playlist.name }}</span>
        </li>
    </ul>
</nav>
