import { Injectable } from '@angular/core';
import { Transformable } from '../transformable';
import { KeyedByString } from '../../interface/keyed-by-string';
import { HttpParameters } from '../../interface/http-parameters';

@Injectable()
export class StringCastHttpParametersTransformer implements Transformable<KeyedByString, HttpParameters> {
    transform(params: KeyedByString): HttpParameters {
        const httpParams = {};

        Object.keys(params).forEach(key => {
            httpParams[key] = Array.isArray(params[key])
                ? params[key].map(val => (null !== val) ? val.toString() : val)
                : params[key].toString();
        });

        return httpParams as HttpParameters;
    }
}
