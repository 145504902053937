import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SettingsResponse } from '../../models/settings-response';
import { AppGatewayUrlService } from '../app-gateway-url/service';

@Injectable()
export class SettingsRepository {
    public constructor(private http: HttpClient, private appGatewayUrlService: AppGatewayUrlService) {
    }

    public fetchAll(): Observable<SettingsResponse> {
        return this.http.get<SettingsResponse>(
          `${ this.appGatewayUrlService.url }/settings`
        );
    }
}
