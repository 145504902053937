import { Injectable } from '@angular/core';
import { SlugCreator } from '../helper/slug-creator';

@Injectable()
export class Slug {
    public constructor(private slug: SlugCreator) {
    }

    public create(name: string, id: number): string | number {
        const slug = this.slug.create(name);
        return slug === null
            ? id
            : slug + '-' + id;
    }

    public parseId(slug: string): number {
        const base = 10;
        const id: any = slug.split('-').pop();

        return isNaN(id) ? null : parseInt(id, base);
    }
}
