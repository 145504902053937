import { Inject, Injectable } from '@angular/core';
import Amplify from '@aws-amplify/core';
import { AmplifyToken } from '../../di/provider/amplify-provider';

@Injectable()
export class AmplifySetup {
    constructor(
        @Inject(AmplifyToken) private amplify: typeof Amplify
    ) {
    }

    public configure(configuration: any): void {
        this.amplify.configure(configuration);
    }

    public register(module: any): void {
        this.amplify.register(module);
    }
}
