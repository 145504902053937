import { FactoryProvider, InjectionToken } from '@angular/core';
import { UAParser } from 'ua-parser-js';

export const UserAgentParser = new InjectionToken('UserAgentParser');

export const UserAgentParserProvider: FactoryProvider = {
    provide: UserAgentParser,
    useFactory: UserAgentParserFactory,
};

export function UserAgentParserFactory() {
    return new UAParser();
}
