<div class="container" role="contentinfo">
    <div class="row">
        <div class="col">
            <app-footer-list></app-footer-list>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="pb-5 text-center">
                <span class="text-white">Powered by <span class="font-weight-bold">Adopt</span></span>
            </div>
        </div>
    </div>
</div>
