export const BROWSER_CONTEXT = 'BROWSER_CONTEXT';
export const PAGE_CONTEXT = 'PAGE_CONTEXT';
export const SELF_SELECT_CONTEXT = 'SELF_SELECT_CONTEXT';
export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_LIST_SUCCESS = 'SETTINGS_LIST_SUCCESS';
export const SETTINGS_LIST_ERROR = 'SETTINGS_LIST_ERROR';
export const USER_SESSION = 'USER_SESSION';
export const COGNITO_CONFIGURATION = 'COGNITO_CONFIGURATION';
export const COGNITO_CONFIGURATION_SUCCESS = 'COGNITO_CONFIGURATION_SUCCESS';
export const COMPANY_ROUTING_IDENTIFIER = 'COMPANY_ROUTING_IDENTIFIER';
export const COGNITO_CONFIGURATION_ERROR = 'COGNITO_CONFIGURATION_ERROR';
export const SELF_SELECT_VISIBILITY = 'SELF_SELECT_VISIBILITY';
export const USER_CONTEXT_PROVIDED_BY = 'USER_CONTEXT_PROVIDED_BY';
