import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Content, Playlist } from '../../../../core/models';

import { StoreProvider } from '../../../../redux-store/providers';
import { IOnStateChanged } from '../../../../redux-store/interfaces';

@Component({
    selector: 'app-playlist-view',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class PlaylistViewComponent implements OnInit, OnDestroy, IOnStateChanged {
    public activePlaylist: Playlist = null;
    public filteredContent: Content[] = [];

    private boundStates = {
        activePlaylist: 'trainingPagesClient/activePlaylist',
        filteredContent: 'trainingPagesClient/filteredContent',
    };

    public constructor(
        private store: StoreProvider,
        private changeDetector: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));
    }

    public onStateChanged(state: string, value: any): void {
        switch (state) {
            case this.boundStates.activePlaylist:
                this.updateProperty('activePlaylist', value);
                break;
            case this.boundStates.filteredContent:
                this.updateProperty('filteredContent', value);
                break;
        }
    }

    private updateProperty(property, value) {
        this[ property ] = value;

        this.changeDetector.detectChanges();
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }
}
