<app-pdf-preview class="embed-responsive embed-responsive-16by9"
                 *ngIf="isPdf()"
                 [name]="contentItem.name"
                 [url]="contentItem.link">
</app-pdf-preview>

<app-brightcove-video-preview *ngIf="isVideo()"
                              [videoId]="contentItem.link"
                              [accountId]="contentItem.bcAccountId"
                              [contentId]="contentItem.id">
</app-brightcove-video-preview>

<div class="content-item__footer rounded-bottom p-3">
    <p role="heading" aria-level="1" class="content-item__title">{{ contentItem.name }}</p>
    <span *ngIf="isPdf()" class="content-item__type font-weight-bold">{{ contentItem.typeName }}</span>
    <span *ngIf="isVideo()" class="content-item__duration font-weight-bold">{{ contentItem.contentFileDuration | videoDuration }}</span>
</div>
