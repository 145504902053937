import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { StoreProvider } from './redux-store/providers';

import { filter, map, mergeMap } from 'rxjs/operators';

import { ContextCreator } from './shared/context/creator';
import {
    createActionSetBrowserContext,
    createActionSetPageContext,
    createActionSetSelfSelectContext,
    createActionSetUserContextProvidedBy,
} from './shared/redux';
import { SelfSelectContext } from './shared/redux/interfaces/selfSelectContext';
import { UserContext } from './shared/context/UserContext';
import { PageTitle } from './shared/services/page-title/page-title';
import { SelfSelectDecorator } from './shared/services/self-select/SelfSelectDecorator';
import { UserContextProvidedBy } from './core/enums/UserContextProvidedBy';
import { ContentSignature } from './shared/services/content/content-signature';
import { Subscription } from 'rxjs/Subscription';
import { DefaultSettings } from './settings/defaultSettings';
import { AdoptSnippetLoader } from './shared/services/adopt-snippet/adopt-snippet-loader';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
    public selfSelectVisibility: boolean;
    public selfSelectContext: SelfSelectContext;

    public boundStates = {
        selfSelectVisibility: 'shared/selfSelectVisibility',
        selfSelectContext: 'shared/selfSelectContext',
    };

    private adoptSnippetLoaded = false;
    private subscriptions: Subscription[] = [];

    constructor(private store: StoreProvider,
                private contextBuilder: ContextCreator,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private selfSelect: SelfSelectDecorator,
                private activatedRoute: ActivatedRoute,
                private pageTitle: PageTitle,
                private contentSignature: ContentSignature,
                private adoptSnippetLoader: AdoptSnippetLoader) {
        this.dispatchBrowserContext();
        this.dispatchPageContext();
    }

    public onStateChanged(state, value): void {
        switch (state) {
            case this.boundStates.selfSelectVisibility:
                this.updateProperty('selfSelectVisibility', value);
                if (true === this.shouldDisplayContextSelfSelect()) {
                    this.subscriptions.push(this.selfSelect.display());
                }
                break;
            case this.boundStates.selfSelectContext:
                this.updateProperty('selfSelectContext', value);
                break;
        }

        if (this.shouldLoadAdoptSnippet()) {
            this.loadAdoptSnippet();
        }
    }

    public ngOnInit(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[state]));

        this.applyPageTitle();
        this.dispatchContextValues();
        this.refreshContentSignature();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private dispatchContextValues(): void {
        const storedContextValues = this.selfSelect.getValues();

        if (false === this.shouldDisplayContextSelfSelect() && null === storedContextValues) {
            return;
        }

        this.store.dispatch(createActionSetSelfSelectContext(storedContextValues));
        this.store.dispatch(createActionSetUserContextProvidedBy(UserContextProvidedBy.SELF_SELECT));
    }

    private shouldDisplayContextSelfSelect(): boolean {
        return true === this.selfSelectVisibility
            && null === this.selfSelect.getValue(UserContext.ROLE)
            && null === this.selfSelect.getValue(UserContext.LANGUAGE)
            && null === this.selfSelect.getValue(UserContext.LOCATION);
    }

    private dispatchPageContext(): void {
        const pageContext = this.contextBuilder.pageContext();

        this.store.dispatch(createActionSetPageContext(pageContext));
    }

    private dispatchBrowserContext(): void {
        const browserContext = this.contextBuilder.browserContext();

        this.store.dispatch(createActionSetBrowserContext(browserContext));
    }

    private updateProperty(property, v): void {
        this[property] = v;

        this.changeDetector.detectChanges();
    }

    private applyPageTitle(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                mergeMap((route) => route.data),
            )
            .subscribe((event: RouterEvent) => this.pageTitle.setTitle(event['pageTitle']));
    }

    private refreshContentSignature(): void {
        this.contentSignature.onInterval(DefaultSettings.contentSignatureRefreshInterval).subscribe();
    }

    private loadAdoptSnippet(): void {
        this.subscriptions.push(this.adoptSnippetLoader.inject());
        this.adoptSnippetLoaded = true;
    }

    private shouldLoadAdoptSnippet(): boolean {
        return this.adoptSnippetLoaded === false &&
            this.selfSelectContext !== undefined &&
            Object.keys(this.selfSelectContext).length > 0;
    }
}
