<h2 class="text-center text-white welcome" role="heading" aria-level="2">Useful Links</h2>

<div class="useful-link-list d-flex flex-wrap justify-content-center"
     role="navigation" aria-label="links">
    <div class="useful-link-list__item-container" *ngFor="let link of usefulLinks">
        <a [style.backgroundColor]="settings?.primaryColor"
           (click)="dispatchLinkClickedEvent(link)"
           class="useful-link-list__item"
           target="_blank"
           href="{{ link.url }}">{{ link.text }}</a>
    </div>
</div>
