import { IAction } from '../../../../redux-store/interfaces';
import { InitialState } from '../interfaces';
import { rootInitialState } from './initial-state';

import {
    BROWSE_MENU_TOGGLE,
    PLAYLIST_LIST,
    PLAYLIST_LIST_SUCCESS,
    CONTENT_LIST,
    CONTENT_LIST_SUCCESS,
    RESET_ACTIVE_CONTENT,
    SET_ACTIVE_CONTENT,
    RESET_ACTIVE_PLAYLIST,
    SET_ACTIVE_PLAYLIST
} from '../actions';

export function trainingPagesClientReducer() {
    return (currentState: InitialState = rootInitialState, action: IAction): InitialState => {
        const { type, payload } = action;

        let newState;

        switch (type) {
            case PLAYLIST_LIST:
                newState = { ...currentState, playlists: [] };
                break;
            case PLAYLIST_LIST_SUCCESS:
                newState = { ...currentState, playlists: payload };
                break;
            case CONTENT_LIST:
                newState = { ...currentState, filteredContent: [] };
                break;
            case CONTENT_LIST_SUCCESS:
                newState = { ...currentState, filteredContent: payload };
                break;
            case BROWSE_MENU_TOGGLE:
                newState = { ...currentState, browseMenu: { active: !currentState.browseMenu.active } };
                break;
            case RESET_ACTIVE_CONTENT:
                newState = { ...currentState, activeContentItem: null };
                break;
            case SET_ACTIVE_CONTENT:
                newState = { ...currentState, activeContentItem: payload };
                break;
            case RESET_ACTIVE_PLAYLIST:
                newState = { ...currentState, activePlaylist: null };
                break;
            case SET_ACTIVE_PLAYLIST:
                newState = { ...currentState, activePlaylist: payload };
                break;
            default:
                newState = currentState;
                break;
        }

        return newState;
    };
}
