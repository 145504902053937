import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { IOnStateChanged } from '../../../../redux-store/interfaces';
import { StoreProvider } from '../../../../redux-store/providers';
import { SimpleLink } from '../../../../core/models';

@Component({
    selector: 'app-home',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class HomeComponent implements OnInit, OnDestroy, IOnStateChanged {
    public homepageLinks: SimpleLink[] = [];

    private boundStates = {
        homepageLinks: 'shared/settings/homepageLinks',
    };

    constructor(private store: StoreProvider,
                private changeDetector: ChangeDetectorRef) {
    }

    public ngOnInit() {
        Object.keys(this.boundStates)
            .forEach(state => this.store.bind(this, this.boundStates[ state ]));
    }

    public onStateChanged(state: string, value: any): void {
        if (state === this.boundStates.homepageLinks) {
            const homepageLinks = this.extractHomepageLinks(value);

            this.updateProperty('homepageLinks', homepageLinks);
        }
    }

    public ngOnDestroy(): void {
        Object.keys(this.boundStates)
            .forEach(state => this.store.unbind(this, this.boundStates[ state ]));
    }

    private extractHomepageLinks(value: any) {
        let homepageLinks;

        try {
            homepageLinks = JSON.parse(value);
        } catch (e) {
            homepageLinks = [];
        }

        return homepageLinks.filter(homepageLinks => homepageLinks !== null);
    }

    private updateProperty(property, value) {
        this[ property ] = value;

        this.changeDetector.detectChanges();
    }
}
