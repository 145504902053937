export interface CognitoConfiguration {
    region: string;
    userPoolId: string;
    webClientId: string;
    domain: string;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
    customProvider: string;
}

export function interfaceOfCognitoConfiguration(object: any): object is CognitoConfiguration {
    return 'region' in object
        && 'userPoolId' in object
        && 'webClientId' in object
        && 'domain' in object
        && 'redirectSignIn' in object
        && 'redirectSignOut' in object
        && 'responseType' in object
        && 'customProvider' in object;
}
