import { Injectable } from '@angular/core';

import { StoreProvider } from '../../redux-store/providers';
import { UserContext } from '../../core/models';
import { UserContext as UserContextType } from '../context/UserContext';
import { UserContextProvidable } from '../interface/user-context-providable';
import { SelfSelectContext } from '../redux/interfaces/selfSelectContext';

@Injectable()
export class SelfSelectContextHandler implements UserContextProvidable {
    private nextHandler: UserContextProvidable;

    public constructor (private store: StoreProvider) {
    }

    public get(): UserContext {
        const context = this.store.getState().shared.selfSelectContext;

        if (this.containsContext(context) === false && undefined !== this.nextHandler) {
            return this.nextHandler.get();
        }

        return {
            languageIds: context.hasOwnProperty(UserContextType.LANGUAGE) ? [ context.language.id ] : [],
            locationIds: context.hasOwnProperty(UserContextType.LOCATION) ? [ context.location.id ] : [],
            roleIds: context.hasOwnProperty(UserContextType.ROLE) ? [ context.role.id ] : [],
        };

    }

    public add(nextHandler: UserContextProvidable): void {
        this.nextHandler = nextHandler;
    }

    private containsContext(context: SelfSelectContext): boolean {
        return Object.keys(context).length > 0;
    }
}
