import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const errorCodes = [ 401, 403 ];

        return next.handle(request)
            .pipe(
                tap(
                    () => null,
                    (err) => {
                        if (err instanceof HttpErrorResponse && errorCodes.includes(err.status)) {
                            this.router.navigate(['unauthorized']);
                        }
                    }
                )
            );
    }
}
