import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import Amplify from '@aws-amplify/core';
import { AmplifyToken } from '../../../../shared/di/provider/amplify-provider';
import { AdoptPlayerHeaders } from '../../../../shared/interface/adopt-player-headers';
import { AdoptPlayer } from '../../../../shared/player/adopt-player';

@Injectable()
export class AdoptPlayerResolver implements Resolve<void> {
    constructor(private adoptPlayer: AdoptPlayer,
                @Inject(AmplifyToken) private amplify: typeof Amplify) {
    }

    async resolve(): Promise<void> {
        const cognitoUserSession = await this.amplify.Auth.currentSession();

        const headersCallback = (): AdoptPlayerHeaders => ({
            'adopt-origin': window.location.origin,
            token: 'none',
            authorization: undefined === cognitoUserSession
                ? 'none'
                : cognitoUserSession.getIdToken().getJwtToken()
        });

        this.adoptPlayer.bootstrapCustomElement(headersCallback);
    }
}
