import { Inject, Injectable } from '@angular/core';

import { StoreProvider } from '../../../../redux-store/providers';

import { EventDispatcher } from '../../../../shared/event/dispatch/EventDispatcher';
import { SharedEventClient } from '../../../../shared/event/sharedEventClient';
import { WindowInjectionToken } from '../../../../shared/di/provider/window';
import { Context } from '../../../../shared/user-context/context';

@Injectable()
export class LinkClicked extends SharedEventClient {
    protected eventType = 'Link Clicked';
    public linkName: string;
    public linkPosition: number;
    public linkDestination: string;

    constructor(
        @Inject(StoreProvider) protected store: StoreProvider,
        @Inject(EventDispatcher) protected eventDispatcher: EventDispatcher,
        @Inject(WindowInjectionToken) protected window: Window,
        @Inject(Context) protected context: Context,
    ) {
        super(store, eventDispatcher, window, context);
    }

    public eventDetail(): any {
        return {
            link_name: this.linkName,
            link_position: this.linkPosition,
            link_destination: this.linkDestination
        };
    }
}
