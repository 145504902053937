import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-pdf-preview',
    templateUrl: './template.html',
    styleUrls: [ './styles.scss' ]
})
export class PdfPreviewComponent {
    @Input() public name: string;
    @Input() public url: string;

    public iframeSrc() {
        return `${ environment.pdfViewerUrl }?file=${ this.url }`;
    }
}
